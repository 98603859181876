'use strict';

function UpdateCtrl(
  $scope,
  $state,
  $stateParams,
  $rootScope,
  formBaseService,
  formModelService,
  $templateCache,
  configuration
) {

  var urlParams = {formId: $stateParams.formId, id: $stateParams.id};

  try {
    formBaseService.decorateControllerScope($scope, $stateParams.formId, $stateParams.id);
  } catch (e) {
    console.error('Redirecting to application list after error', e);
    $state.go('main.forms.list');
    return;
  }

  $scope.model = {};

  $scope.errorState = false;
  $scope.formResultError = false;

  // default error message
  $scope.errorTitle = '';
  $scope.errorMessage = '';

  $scope.formSubmissionWithoutClientIDEnabled = function () {
    $scope.submissionWarningPath = '';
    $scope.submissionDisabledPath = '';
    // some customers disable submission until user has a client ID
    // (in order to avoid creation of subjectless steps in mosaic)
    if ($scope.formDefinition.blockSubmissionWithoutClientID) {
      //  if blockSubmissionWithoutClientID is true
      // then they MUST have a client ID in order to submit

      const submissionEnabled = $scope.model.hasClientID !== false;

      if (!submissionEnabled) {
        const customer = configuration.customer;
        $scope.submissionWarningPath = 'modules/forms/views/update/submissionWarning.html';
        $scope.submissionDisabledPath = 'modules/forms/views/update/submissionDisabledWithoutClientID.html';

        const hasSubmissionDisabledTemplate = $templateCache.get('modules/forms/views/update/' + customer + '/submissionDisabledWithoutClientID.html');
        if (hasSubmissionDisabledTemplate) {
          $scope.submissionDisabledPath = 'modules/forms/views/update/' + customer + '/submissionDisabledWithoutClientID.html';
        }
        const hasSubmissionWarningTemplate = $templateCache.get('modules/forms/views/update/' + customer + '/submissionWarning.html');
        if (hasSubmissionWarningTemplate) {
          $scope.submissionWarningPath = 'modules/forms/views/update/' + customer + '/submissionWarning.html';
        }
      }

      return submissionEnabled;
    }
    // default is to enable submission
    return true;
  };

  $scope.loadForm()
    .then(function () {
      if ($scope.formDefinition.is_submit_once && $scope.formModelInstance.isSubmitted() && !$rootScope.app.hasRole('ROLE_ADMIN')) {
        $state.go('^.review', urlParams, {location: 'replace'});
      } else if ($stateParams.populateUserDetails === 'true') {
        return formModelService.setDefaultFieldValues($scope[$scope.formModelFieldsVariable], $scope.formDefinition);
      }
    })
    .catch(function (e) {
      console.error('Redirecting to application list after error', e);
      $state.go('main.forms.list');
    });

  $scope.isFormVisible = function() {
    return !$scope.loadingMessage && !$scope.errorState && !$scope.formResultError;
  };

  $scope.isErrorVisible = function() {
    return $scope.errorState;
  };

  /**
   * Save any pending changes to the form fields if the user leaves by clicking the back button, a link etc.
   */
  $scope.$on("$destroy", function () {
    // single-submit forms' data should never be changed after they've been submitted.
    var isSingleSubmit = $scope.formDefinition && $scope.formDefinition.is_submit_once;
    var isSubmitted = $scope.formModelInstance && $scope.formModelInstance.isSubmitted();
    if (!isSingleSubmit && !isSubmitted) {
      $scope.updateForm(true);
    }
  });

  $scope.showDebugModel = false;

  $scope.toggleDebugModel = function () {
    $scope.showDebugModel = !$scope.showDebugModel;
  };

  $rootScope.$on('form-error-fatal', function(e, args) {
    $scope.errorState = true;
    if (args && args.error) {
      $scope.errorTitle = args.error.title;
      $scope.errorMessage = args.error.message;
    }
  });

  $rootScope.$on('form-result-validation-error', function(e, args) {
    $scope.formResultError = true;
  });

  $rootScope.$on('form-result-clear', function(e, args) {
    $scope.formResultError = false;
  });
}

UpdateCtrl.$inject = [
  '$scope', '$state', '$stateParams', '$rootScope', 'formBaseService', 'formModelService', '$templateCache', 'configuration'
];

angular.module('kerp-forms.forms').controller('UpdateCtrl', UpdateCtrl);
