'use strict';

angular
  .module('kerp-forms.forms')
  .factory(
    'formBaseService',
    [
      '$q',
      '$timeout',
      "Forms",
      "$injector",
      "Loader",
      '$state',
      'formModelService',
      'toaster',
      'fieldDefinitionService',
      'analyticsService',
      'schemaFormValidatorService',
      'schemaFormHelperService',
      'FormConditions',
      'FormErrorType',
      '$rootScope',
      '$window',
      '$exceptionHandler',

      function (
        $q,
        $timeout,
        Forms,
        $injector,
        Loader,
        $state,
        formModelService,
        toaster,
        fieldDefinitionService,
        analyticsService,
        schemaFormValidatorService,
        schemaFormHelperService,
        FormConditions,
        FormErrorType,
        $rootScope,
        $window,
        $exceptionHandler

      ) {

        var DEFAULT_FORM_MODEL_VARIABLE = "model";
        var formFieldsHash;

        function updateFormFieldsHash(formModel) {
          formFieldsHash = formModelService.formFieldsHashCode(formModel.dirtyFields);
        }

        function defaultFormModelInstanceGetter(formModelInstanceId) {
          return formModelService.getForm(formModelInstanceId).then(function (formModelInstance) {

            // data migration
            // form model instance has just been populated from API response
            // - it may be empty (e.g. a new form has been started)
            // - it may be fully populated (e.g. a freshly opened clone)
            // - it may be partly populated (e.g. re-opening an underway assessment)
            // we need to check if the data contained is out of date due to schema migrations

            // the calcs package exposes a method which accepts a form type code (e.g. LI_FCA)
            // which returns function, which accepts the form data
            // if there are migrations, the function modifies the form data to comply with the latest schema version

            // for webclient - the optimal time to do this is here
            // *before* the form field hash is created
            // so that the data changes are picked up and put back to the server at the correct time

            const { dirtyFields, formTypeCode } = formModelInstance;
            try {
              const migrator = $window.kerpCfa.migrate(formTypeCode);
              const migratedData = migrator(dirtyFields);
              formModelInstance.dirtyFields = migratedData;
            } catch (e) {
              $exceptionHandler(e, FormErrorType.FORM_MIGRATION_ERROR, undefined, {
                formTypeCode,
                data: formModelInstance
              });
            }

            updateFormFieldsHash(formModelInstance);
            return formModelInstance;
          });
        }

        function defaultFormModelInstanceUpdateHandler(formModelInstance, formFields) {
          formModelInstance = formModelInstance || {};
          formModelInstance.dirtyFields = formFields;

          return formModelService.updateFormAndFacts(formModelInstance, formFieldsHash).then(function (updatedFormModel) {
            updateFormFieldsHash(updatedFormModel);
            return updatedFormModel;
          });
        }

        /**
         * By default Prev/Next buttons are hidden when user
         * visits last page and clicks [Next]. In the case when form is allowed to be submitted once
         * going back is not expected and therefore does not cause issues. In the case ordinary forms
         * pagination is shown when Prev/Next is hidden
         * @param formModelInstance {FormModel}
         * @returns {boolean}
         */
        function defaultAreFormNavigationButtonsVisibleHandler(formModelInstance) {
          return !!formModelInstance && !formModelService.allPagesValid(formModelInstance);
        }

        /**
         * This is called after form page is considered valid and after
         * formModelInstance is stored
         * @param formService
         * @param formFields
         * @param formModelInstance
         * @returns {*}
         */
        function defaultOnNextPaginationHandler(formService, formFields, formModelInstance) {

          var pageIncremented = formService.next(formFields);

          if (!pageIncremented) {
            formModelInstance.allPagesValidated = true;
          }
        }

        function defaultOnPrevPaginationHandler(formService, formFields, formDefinition) {
          if (formFields && formFields.page > 1) {
            formService.prev(formFields);
          } else {
            $state.go('main.forms.show', {formId: formDefinition.id});
          }
        }

        function defaultOnAbandonHandler(isFormComplete, formDefinition, formModelInstance) {
          if (!isFormComplete) {
            analyticsService.sendEvent('form', 'abandon', formDefinition.id);
            console.log('abandon event sent for form', formDefinition.id);
          }

          if ($rootScope.app.isOffline) {
            toaster.pop('error', "Failed to save changes to the form, please try again later");

          } else {
            // if the operation fails let the user to navigate away
            // there is not much he can do to fix the error
            formModelService.updateFormAndFacts(formModelInstance, formFieldsHash)
              .finally(function () {
                $state.go('main.forms.show', {formId: formDefinition.id});
              });
          }
        }

        /**
         * Utility function to make sure you are scrolling to an element
         * that might change position due to other ones rendering around it.
         * Just provide the time to watch for element positional changes,
         * if element changes position in that period, then animation
         * will follow it.
         * @param el$ {jQuery}
         * @param followElementPositionDuration {Number} milliseconds
         */
        function scrollToAndFollow(el$, followElementPositionDuration) {
          if (!el$ || el$.length !== 1) {
            return;
          }
          var startTime = new Date().getTime();
          var endTime, scrollDuration;
          var startPostion = el$.offset().top;
          $('html, body').animate({
            scrollTop: startPostion
          }, {
            duration: 50,
            complete: function () {
              endTime = new Date().getTime();
              scrollDuration = endTime - startTime;
              if (scrollDuration < followElementPositionDuration) {
                scrollToAndFollow(el$, followElementPositionDuration - scrollDuration);
              }
            }
          });
        }

        /**
         * Validate form pages one by one, terminate on invalid one and return its number
         * @return {number}
         */
        function findFirstInvalidPageNumber(mergedFormAndSchema, formFields, validationLib, scope, upToPage) {
          var pageNum = -1;
          var pageErrors = [];

          var hiddenPages = scope.formService.getHiddenPages(formFields);

          for (var i = 0; i < mergedFormAndSchema.length; i++) {
            var formPage = mergedFormAndSchema[i];
            pageNum = formPage.page;

            if (upToPage && pageNum >= upToPage) {
              break;
            }

            if (hiddenPages.indexOf(pageNum) > -1) {
              continue;
            }

            var pageValidationResult = schemaFormValidatorService.validate(
              [formPage],
              formFields,
              validationLib,
              scope);

            pageErrors = pageValidationResult.filter(function (field) {
              return !field.valid;
            });

            if (pageErrors.length) {
              console.log(pageErrors.length, "errors found on page", pageNum, "First error:", pageErrors[0].error);
              break;
            }
          }

          return pageErrors.length ? pageNum : -1;
        }

        this.findFirstInvalidPageNumber = findFirstInvalidPageNumber;

        /**
         * Validate form pages up until a given value
         * if they contain errors, promise will be rejected
         *
         * @return {Promise}
         */
        function validatePagesUpTo(upToPage, mergedFormAndSchema, formFields, conditionsLib, scope) {

          var deferred = $q.defer();
          var validatingAllForm = false;

          if (!upToPage) {
            validatingAllForm = true;
          }

          $timeout(function () {
            var firstInvalidPage = findFirstInvalidPageNumber(mergedFormAndSchema, formFields, conditionsLib, scope, upToPage);

            if (firstInvalidPage > -1 && (validatingAllForm || firstInvalidPage < upToPage)) {
              scope.$emit('renderSchemaFormPageError', firstInvalidPage, scope.current_form);
              formModelService.setAllPagesInvalid(scope.formModelInstance);
              deferred.reject(FormErrorType.VALIDATION_FAILED);
            }
            deferred.resolve();
          });

          return deferred.promise;
        }

        this.validatePagesUpTo = validatePagesUpTo;

        /**
         * Decorate controller scope and give it necessary functions/variables to work with forms
         *
         * @param scope
         * @param formDefinitionId
         * @param formModelInstanceId
         * @param additionalOptions - additional options / overrides
         * @constructor
         */
        function decorateControllerScope(scope, formDefinitionId, formModelInstanceId, additionalOptions) {

          if (!scope) {
            throw new Error('scope required');
          }

          if (!formDefinitionId) {
            throw new Error('formDefinitionId required');
          }

          if (!formModelInstanceId) {
            throw new Error('formModelInstanceId required');
          }

          var options = angular.merge({
            formModelFieldsVariable: DEFAULT_FORM_MODEL_VARIABLE,
            syncToLocalStorage: false,
            allowNavigationWhenInvalid: false,
            formModelInstanceGetter: defaultFormModelInstanceGetter,
            formModelInstanceUpdateHandler: defaultFormModelInstanceUpdateHandler,
            areFormNavigationButtonsVisibleHandler: defaultAreFormNavigationButtonsVisibleHandler,
            onNextPagination: defaultOnNextPaginationHandler,
            onPrevPagination: defaultOnPrevPaginationHandler,
            onAbandon: defaultOnAbandonHandler
          }, additionalOptions || {});

          scope.formModelFieldsVariable = options.formModelFieldsVariable;
          try {
            scope.formDefinition = Forms.getForm(formDefinitionId);
          } catch (e) {
            $timeout(function () {
              toaster.pop('error', e.message);
            });
            throw e;
          }
          scope.fieldDefinitionService = fieldDefinitionService;
          scope.formService = $injector.get(scope.formDefinition.formService);
          scope.form = scope.formService.get();
          scope.schema = $injector.get(scope.formDefinition.formSchema).getSchema();
          scope.mergedFormAndSchema = schemaFormHelperService.mergeFormAndSchema(scope.form, scope.schema);
          try {
            var formConditions = $injector.get(scope.formDefinition.formConditions);
            scope[FormConditions.SCOPE_PROPERTY_NAME] = formConditions;
            formConditions.setModelGetter(function () {
              return scope[options.formModelFieldsVariable];
            });
          } catch (e) {
            console.log('Form', scope.formDefinition.id, 'does not have conditions set');
          }

          var loader = new Loader(scope, 'loadingMessage');

          /**
           * Retrieves form schema definition of the form with form id as formDefinitionId specified in this class
           * @returns {*}
           */
          scope.getSchema = function () {
            return scope.schema;
          };

          /**
           * Save any changes to the form model
           * @param isAbandoned false if the update was initiated by the user, e.g. by clicking prev/next, true if the
           * update was performed automatically when the user navigated away from the form
           * @returns {*}
           */
          scope.updateForm = function (isAbandoned) {
            scope.formModelInstance.isAbandoned = isAbandoned;
            return options.formModelInstanceUpdateHandler(scope.formModelInstance, scope[options.formModelFieldsVariable])
              .then(function (updatedFormModel) {

                if (updatedFormModel) {
                  // KERP-1859 if our update only changes the current page number, the current model returned by the server
                  // may be different from that stored locally, if changes are being made concurrently by another
                  // user/device/window
                  scope.formModelInstance = updatedFormModel;
                  scope.model = updatedFormModel.dirtyFields;
                  return updatedFormModel;
                }
              })
              .finally(function () {
                scope.formModelInstance.isAbandoned = false;
              });
          };

          scope.isSubmittedRadioValues = {
            submitted: true,
            notsubmitted: false
          };

          scope.toggleNonSingleSubmitFormSubmittedProgress = false;
          scope.toggleNonSingleSubmitFormSubmitted = function (shouldBeSubmitted) {

            if (scope.formDefinition.is_submit_once) {
              throw new Error('toggleFormSubmitted should be used only by non single submit forms');
            }

            scope.toggleNonSingleSubmitFormSubmittedProgress = true;

            if (shouldBeSubmitted) {
              scope.formModelInstance.setSubmittedFields(scope.formModelInstance.dirtyFields);
            } else {
              scope.formModelInstance.setSubmittedFields(null);
            }

            return options.formModelInstanceUpdateHandler(scope.formModelInstance, scope[options.formModelFieldsVariable])
              .finally(function () {
                scope.isSubmittedRadio = scope.formModelInstance.isSubmitted();
                scope.toggleNonSingleSubmitFormSubmittedProgress = false;
              });
          };

          /**
           * It is like "return later" to user
           */
          scope.abandon = function () {
            options.onAbandon(scope.isFormComplete(), scope.formDefinition, scope.formModelInstance);
          };

          scope.isFormComplete = function () {
            if (!scope.formModelInstance) {
              return false;
            }
            return scope.formModelInstance.isSubmitted() || formModelService.allPagesValid(scope.formModelInstance);
          };

          /**
           * Condition used on form Prev/Next buttons
           * @returns {*}
           */
          scope.areFormNavigationButtonsVisible = function () {
            return !!options.areFormNavigationButtonsVisibleHandler(scope.formModelInstance);
          };

          scope.validatePreviousPages = function () {
            var currentPage = scope[options.formModelFieldsVariable] && scope[options.formModelFieldsVariable].page;
            return validatePagesUpTo(currentPage, scope.mergedFormAndSchema, scope[options.formModelFieldsVariable], scope[FormConditions.SCOPE_PROPERTY_NAME], scope);
          };

          scope.validateAllPages = function () {
            return validatePagesUpTo(null, scope.mergedFormAndSchema, scope[options.formModelFieldsVariable], scope[FormConditions.SCOPE_PROPERTY_NAME], scope);
          };

          /**
           * Helper
           * Allows to set values from within form definition, eg:
           * {
           *   type: 'button',
           *   style: 'btn-default',
           *   title: 'Add benefit',
           *   onClick: "setPropertyInFormModel('nbBenefit', 2)"
           * }
           * @param {string} key
           * @param {*} value
           */
          scope.setPropertyInFormModel = function (key, value) {
            if (!angular.isString(key) || !angular.isDefined(value)) {
              throw new Error('setPropertyInFormModel requires key and value');
            }
            if (angular.isObject(scope[options.formModelFieldsVariable])) {
              scope[options.formModelFieldsVariable][key] = value;
            }
          };

          scope.validateCurrentPage = function () {
            toaster.clear();
            scope.$broadcast('schemaFormValidate');
            scope.$emit('clearSchemaFormErrors');

            var isValid = scope.current_form.$valid;

            if (!isValid) {
              formModelService.setAllPagesInvalid(scope.formModelInstance);
            }

            if (!options.allowNavigationWhenInvalid) {
              scope.$emit('checkSchemaFormErrors', scope.current_form);
            }

            return isValid;
          };

          /**
           * Access the next page of the form
           * The page is checked and has to be valid to access to the next page
           * The page is saved anyway
           */
          scope.next = function () {

            if (scope.validateCurrentPage() || options.allowNavigationWhenInvalid) {
              $('html, body').animate({scrollTop: 0}, 50);
              loader.start();

              var validationPromise;

              if (options.allowNavigationWhenInvalid) {
                validationPromise = $q(function (resolve) {
                  resolve();
                });
              } else {
                validationPromise = scope.validatePreviousPages();
              }

              var formFieldsModel = scope[options.formModelFieldsVariable];

              return validationPromise
                .then(function () {
                  return options.onNextPagination(scope.formService, formFieldsModel, scope.formModelInstance);
                })
                .then(function () {
                  return scope.updateForm();
                })
                .finally(function () {
                  loader.stop();
                });

            } else {
              return $q.reject(FormErrorType.VALIDATION_FAILED);
            }
          };

          /**
           * Access to the previous page of the form
           * It the user is on the first page of the form, he is redirected to form list
           */
          scope.prev = function () {
            $('html, body').animate({scrollTop: 0}, 50);
            return $q(function (resolve, reject) {
              resolve();
            })
              .then(function () {
                return options.onPrevPagination(scope.formService, scope[options.formModelFieldsVariable], scope.formDefinition);
              })
              .then(function () {
                return scope.updateForm();
              });
          };

          scope.loadForm = function () {

            loader.start();

            return options.formModelInstanceGetter(formModelInstanceId)
              .then(function (formModelInstance) {
                scope.formModelInstance = formModelInstance;
                scope.isSubmittedRadio = formModelInstance.isSubmitted();
                scope[options.formModelFieldsVariable] = formModelInstance.dirtyFields;

                if (options.syncToLocalStorage) {
                  return formModelService.syncScopeFormFieldsToLocalStorage(scope, options.formModelFieldsVariable, scope.formModelInstance)
                    .catch(function (error) {
                      toaster.pop('error', error);
                      $state.go('main.forms.show', {formId: scope.formDefinition.id});
                    });
                }

                return formModelInstance;
              })
              .then(function () {

                if (!scope[options.formModelFieldsVariable]) {
                  scope[options.formModelFieldsVariable] = {};
                }

                if (!scope[options.formModelFieldsVariable].page) {
                  scope[options.formModelFieldsVariable].page = 1;
                }

                //Send events to Google Analytics when forms are submitted and completed
                // TODO: change it so submit action sends events instead of relying on watcher
                scope.$watch(function () {
                  return scope.formModelInstance && scope.formModelInstance.isSubmitted();
                }, function (newValue, oldValue) {
                  if (!oldValue && newValue) {
                    analyticsService.sendEvent('form', 'submit', scope.formDefinition.id);
                    console.log('submit event sent for form', scope.formDefinition.id);
                  }
                });

                scope.$watch(function () {
                  return scope.isFormComplete();
                }, function (newValue, oldValue) {
                  if (!oldValue && newValue) {
                    analyticsService.sendEvent('form', 'complete', scope.formDefinition.id);

                    // scroll the window to the "form complete" navigation panel (KERP-338)
                    scrollToAndFollow($("#form-complete"), 1000);
                  }
                });

                scope.validatePreviousPages();

              })
              .finally(function () {
                loader.stop();
              });

          };
        }

        return {
          decorateControllerScope: decorateControllerScope
        };
      }]);
