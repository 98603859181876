'use strict';
angular.module('kerp-forms.forms')
  .factory(
    'DT_FCA_FORM',
    [
      'FormUI',
      'dorsetCareAssessmentCalculator',
      'sfSelect',
      'htmlService',
      'fieldDefinitionService',
      'DT_FCA_CONDITIONS',
      '$window',

      function (
        FormUI,
        careAssessmentCalculator,
        sfSelect,
        htmlService,
        fieldDefinitionService,
        conditions,
        $window
      ) {

        var constants = $window.kerpCfa.dorset.constants;

        var formUI = new FormUI();

        function periodicPaymentField(fieldName, options) {
          return {
            type: 'section',
            htmlClass: 'row',
            items: [
              angular.extend({
                key: fieldName,
                htmlClass: "col-md-8",
                feedback: false
              }, options),
              {
                key: fieldName + 'Period',
                condition: 'model.' + fieldName,
                htmlClass: "col-md-4"
              }
            ]
          };
        }

        function fullWidthPeriodicPaymentField(fieldName, options, condition) {
          condition = condition || 'true';

          return {
            type: 'section',
            htmlClass: 'row',
            condition: condition,
            items: [
              angular.extend({
                key: fieldName,
                htmlClass: "col-md-7 col-sm-8",
                feedback: false
              }, options),
              {
                key: fieldName + 'Period',
                condition: 'model.' + fieldName,
                htmlClass: "col-md-3 col-sm-4"
              }
            ]
          };
        }

        function paymentField(fieldName, overrides) {
          return angular.extend({
            key: fieldName,
            feedback: false
          }, overrides);
        }

        /**
         * Recalculate the cost of care on submission because the user could use the pagination controls to change
         * an input field (without revisiting the calculation page) before submitting
         * @param dirtyModel
         * @param cleanModel
         */
        formUI.preSubmissionHandler = function (dirtyModel, cleanModel) {
          careAssessmentCalculator.recalculate(dirtyModel, cleanModel);
        };

        formUI.getHiddenPages = function (model) {
          var intro = (model.person || {}).intro || {};
          var withholdFinancialDetailsAndPayFullCost = intro.provideFinancialDetails === 'payFullCost';
          var withholdFinancialDetailsAndArrangeOwnCare = intro.provideFinancialDetails === 'arrangeMyOwn';

          let hiddenPages = [];

          if (withholdFinancialDetailsAndPayFullCost) {
            hiddenPages = [3, 4, 5, 6, 7, 8, 9];
          } else if (withholdFinancialDetailsAndArrangeOwnCare) {
            hiddenPages = [3, 4, 5, 6, 7, 8, 9, 10, 11];
          } else {
            hiddenPages = [];
          }

          return hiddenPages;
        };

        formUI.setForm([
          {
            type: 'section',
            condition: 'model.page === 1',
            page: 1,
            htmlClass: 'row',
            items: [
              {
                type: 'fieldset',
                htmlClass: 'col-md-12',
                items: [
                  {
                    type: 'template',
                    templateUrl: 'modules/forms/scripts/services/forms/fca_animations/templates/cfa_animation_01_intro.html'
                  },
                ]
              },
              {
                type: 'fieldset',
                title: 'People acting on your behalf',
                htmlClass: 'col-md-12',
                items: [
                  {
                    type: 'template',
                    templateUrl: 'modules/forms/scripts/services/forms/DT_FCA/templates/agentVideoGuidance.html'
                  },
                  'agent.hasAppointee',
                  {
                    type: "help",
                    helpvalue: "<div class='alert alert-info html-view-remove'>Appointeeship for State Benefits gives a person the right to deal with the benefits of someone who cannot manage their own affairs because they\'re mentally incapable or severely disabled. Additional information is available on <a href=\"https://www.gov.uk/become-appointee-for-someone-claiming-benefits\">the government website</a>.</div>"
                  },
                  {
                    condition: 'model.agent.hasAppointee',
                    key: 'agent.appointeeDetails'
                  },
                  'agent.hasDeputy',
                  {
                    type: "help",
                    helpvalue: "<div class='alert alert-info html-view-remove'>You are someone’s deputy if they \'lack mental capacity\'. This means they cannot make a decision for themselves at the time it needs to be made. They may still be able to make decisions for themselves at certain times. Additional information is available on <a href=\"https://www.gov.uk/become-deputy\">the government website</a>.</div>"
                  },
                  {
                    condition: 'model.agent.hasDeputy',
                    key: 'agent.deputyDetails'
                  },
                  'agent.hasPowerOfAttorney',
                  {
                    type: "help",
                    helpvalue: "<div class='alert alert-info html-view-remove'>A power of attorney is a legal document that allows someone to make decisions for you, or act on your behalf, if you\'re no longer able to or if you no longer want to make your own decisions. Additional information is available on <a href=\"https://www.gov.uk/power-of-attorney\">the government website</a>.</div>"
                  },
                  {
                    condition: 'model.agent.hasPowerOfAttorney',
                    key: 'agent.powerOfAttorneyDetails'
                  },
                  'agent.isCompletedByAgent',
                  {
                    condition: 'model.agent.isCompletedByAgent',
                    key: 'agent.completedByDetails'
                  }
                ]
              },
            ]
          },
          {
            type: 'section',
            condition: 'model.page === 2',
            page: 2,
            htmlClass: "row",
            items: [
              {
                type: "fieldset",
                htmlClass: "col-md-12",
                items: ['clientID']
              },
              {
                type: 'fieldset',
                htmlClass: 'col-md-6',
                title: 'About you',
                items: [
                  {
                    type: 'template',
                    htmlClass: 'divTitle',
                    templateUrl: 'modules/forms/scripts/services/forms/fca_animations/templates/cfa_animation_02_about-you.html'
                  },
                  'person.details.title',
                  'person.details.firstName',
                  'person.details.middleName',
                  'person.details.lastName',
                  {
                    type: "template",
                    templateUrl: "modules/forms/scripts/services/forms/DT_FCA/templates/addressLookup.html",
                    homeAddressDescription: "If you have moved into a care home please put the address that you were living at before your move."
                  },
                  // FIXME: these fields are already defined in the addressSuggestion directive's template, but they are not included in the submitted model unless they are repeated here
                  {
                    key: 'person.details.address.line1',
                    title: 'Address line 1',
                    type: "hidden"
                  },
                  {
                    key: 'person.details.address.line2',
                    title: 'Address line 2',
                    type: "hidden"
                  },
                  {
                    key: 'person.details.address.line3',
                    title: 'Address line 3',
                    type: "hidden"
                  },
                  {
                    key: 'person.details.address.postcode',
                    title: 'Postcode',
                    type: "hidden"
                  },
                  {
                    key: 'person.details.address.uprn',
                    title: 'Property Reference',
                    type: "hidden"
                  },
                  'person.details.homePhoneNumber',
                  'person.details.mobilePhoneNumber',
                  'person.details.email',
                  'person.details.nhsNumber',
                  {
                    key: 'person.details.dob',
                    description: 'We need this information to calculate your State Pension age'
                  }
                ]
              },


              {
                type: 'fieldset',
                title: "Declaring your financial circumstances",
                htmlClass: "col-md-12",
                items: [
                  'person.intro.provideFinancialDetails',
                  {
                    key: 'person.details.nationalInsuranceNumber',
                    condition: 'model.person.intro.provideFinancialDetails === "agree"',
                  },
                  {
                    key: 'person.intro.hasRecentOvernightStay',
                    condition: 'model.person.intro.provideFinancialDetails === "agree"',
                  },
                  {
                    key: 'person.intro.overnightStays',
                    add: 'More stays',
                    condition: 'model.person.intro.provideFinancialDetails === "agree" && model.person.intro.hasRecentOvernightStay',
                  },
                  {
                    key: 'person.intro.hasPartner',
                    condition: 'model.person.intro.provideFinancialDetails === "agree"',
                    description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/DT_FCA/templates/partnerDescription.html')
                  },
                  {
                    key: 'person.intro.partnerStatus',
                    condition: 'model.person.intro.hasPartner && model.person.intro.provideFinancialDetails === "agree"',
                  },
                  {
                    key: 'person.intro.otherRecievesCarersAllowance',
                    condition: 'model.person.intro.provideFinancialDetails === "agree"'
                  },
                  {
                    key: 'person.intro.carersAllowanceIsEntitled',
                    condition: 'model.person.intro.provideFinancialDetails === "agree"',
                    description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/DT_FCA/templates/carersAllowanceEntitlement.html')
                  },
                ]
              },

              {
                type: 'fieldset',
                htmlClass: 'col-md-6',
                title: 'About your partner',
                condition: 'model.person.intro.hasPartner && model.person.intro.provideFinancialDetails === "agree"',
                items: [
                  'partner.intro.alreadyReceivingCare',
                  {
                    key: 'partner.details.partnerReceivesResidentialCare',
                    condition: 'model.person.intro.partnerStatus === "spouse"',
                  },
                  'partner.details.sameAddress',
                  {
                    type: 'fieldset',
                    condition: 'model.partner.details.sameAddress === true',
                    items: [
                      'partner.details.firstName',
                      'partner.details.middleName',
                      'partner.details.lastName',
                      'partner.details.homePhoneNumber',
                      'partner.details.mobilePhoneNumber',
                      'partner.details.email',
                      'partner.details.dob',
                      {
                        key: 'partner.intro.discloseFinances',
                        description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/DT_FCA/templates/partnersFinancesDescription.html')
                      },
                      {
                        key: 'partner.details.nationalInsuranceNumber',
                        condition: 'model.partner.intro.discloseFinances'
                      },
                      {
                        key: 'partner.intro.carersAllowanceIsEntitled',
                        condition: 'model.partner.intro.discloseFinances'
                      },
                      {
                        key: 'partner.intro.otherRecievesCarersAllowance',
                        condition: 'model.partner.intro.discloseFinances'
                      }
                    ],
                  }
                ]
              }
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 3',
            page: 3,
            title: 'People living with you',
            items: [
              {
                key: 'peopleLivingWithYou.hasDependentChildren',
                description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/DT_FCA/templates/dependentChildrenDescription.html')
              },
              {
                key: 'peopleLivingWithYou.dependentChildren',
                condition: 'model.peopleLivingWithYou.hasDependentChildren',
                add: "Add Child",
                title: 'Dependent children details',
                validationMessage: 'Complete all required fields for at least one child'
              },
              {
                key: 'peopleLivingWithYou.hasOtherPeople',
                description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/DT_FCA/templates/nonDependentsDescription.html')
              },
              {
                key: 'peopleLivingWithYou.otherPeople',
                condition: 'model.peopleLivingWithYou.hasOtherPeople',
                add: "Add Person",
                title: 'Details of other people living with you',
                validationMessage: 'Complete all required fields for at least one person'
              }
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 4',
            page: 4,
            items: [
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: 'Your income and benefits',
                    htmlClass: "col-md-6",
                    items: [
                      {
                        type: 'template',
                        htmlClass: 'divTitle',
                        templateUrl: 'modules/forms/scripts/services/forms/fca_animations/templates/cfa_animation_04_income-intro.html'
                      },
                      {
                        type: "help",
                        helpvalue: "<h4 class='section-title'>Wages/Salary</h4>"
                      },
                      {
                        key: 'person.income.hasWage',
                        title: 'Are you currently employed?'
                      },
                      {
                        key: 'person.income.wages',
                        condition: 'model.person.income.hasWage',
                        add: "Add salary/wage",
                        title: 'Wages/Salary',
                        validationMessage: 'Enter details of at least one employer'
                      },
                      {
                        type: "help",
                        helpvalue: "<h4 class='section-title'>Private/Works Pension or Annuity</h4>"
                      },
                      {
                        key: 'person.income.hasPrivatePension',
                        title: 'Are you in receipt of private/works pension or annuity?'
                      },
                      {
                        key: 'person.income.privatePensions',
                        condition: 'model.person.income.hasPrivatePension',
                        add: "Add more",
                        title: 'Private/Works Pensions',
                        validationMessage: 'Enter details of at least one private/works pension'
                      },

                      {
                        key: 'person.income.hasUniversalCredit',
                        title: 'Are you in receipt of Universal Credit?',
                        description: 'Universal Credit (UC) is made up of several elements, not all of these will be used in your financial assessment for adult care. The elements you receive are listed on your UC award. In order to work out the amount of UC to use in your assessment, you need to <b>enter the monthly amount</b> of all the elements of UC you receive and all the amounts deducted from your UC payment, as listed on your award letter.',
                      },
                      {
                        key: 'person.income.universalCredit',
                        notitle: true,
                        condition: 'model.person.income.hasUniversalCredit'
                      },
                      {
                        key: 'person.income.universalCreditBreakdown',
                        notitle: true,
                        title: 'Universal Credit Allowances',
                        condition: 'model.person.income.hasUniversalCredit'
                      },
                      {
                        type: "help",
                        helpvalue: "<h4 class='section-title'>Are you in receipt of any of the benefits listed below?</h4>"
                      },

                      periodicPaymentField('person.income.retirementPension'),

                      fieldDefinitionService.groups.periodicJointPaymentFormFields({key: 'person.income.incomeSupportPayment'}, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),
                      periodicPaymentField('person.income.incapacityBenefit'),
                      fieldDefinitionService.groups.periodicJointPaymentFormFields({key: 'person.income.employmentSupport'}, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),
                      {
                        key: 'person.income.employmentSupportGroup',
                        condition: 'model.person.income.employmentSupport'
                      },
                      fieldDefinitionService.groups.periodicJointPaymentFormFields({key: 'person.income.jobSeekersAllowance'}, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),

                      periodicPaymentField('person.income.industrialInjuriesBenefit'),
                      fieldDefinitionService.groups.periodicJointPaymentFormFields({key: 'person.income.rentalIncome'}, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),
                      fieldDefinitionService.groups.periodicJointPaymentFormFields({key: 'person.income.workingTaxCredit'}, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),
                      fieldDefinitionService.groups.periodicJointPaymentFormFields({key: 'person.income.childTaxCredit'}, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),
                      periodicPaymentField('person.income.maintenance'),
                      periodicPaymentField('person.income.warPension'),
                      periodicPaymentField('person.income.warWidowsPension'),
                      periodicPaymentField('person.income.warSpecialPayment'),
                      fieldDefinitionService.groups.periodicJointPaymentFormFields({key: 'person.income.childBenefit'}, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),
                      periodicPaymentField('person.income.carersAllowance'),

                      paymentField('person.income.attendanceAllowance', {
                        condition: conditions.toString('person.income.attendanceAllowance')
                      }),

                      {
                        key: 'person.income.receivesExtraCare',
                        condition: 'model.person.income.attendanceAllowance && model.person.income.attendanceAllowance !== "none"'
                      },

                      paymentField('person.income.dlaCare', {
                        condition: conditions.toString('person.income.dlaCare')
                      }),
                      paymentField('person.income.pipDailyLiving', {
                        condition: conditions.toString('person.income.pipDailyLiving')
                      }),

                      paymentField('person.income.mobilityAllowance'),
                      fieldDefinitionService.groups.periodicJointPaymentFormFields({key: 'person.income.pensionGuaranteeCredit'}, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),

                      {
                        key: 'person.income.pgcIncludesSDP',
                        condition: 'model.person.income.pensionGuaranteeCredit'
                      },

                      periodicPaymentField('person.income.pgcSDPComponent', {
                        condition: 'model.person.income.pgcIncludesSDP'
                      }),

                      fieldDefinitionService.groups.periodicJointPaymentFormFields({key: 'person.income.pensionSavingsCredit'}, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),

                      periodicPaymentField('person.income.charitableIncome'),

                      periodicPaymentField('person.income.housingBenefit'),

                      periodicPaymentField('person.income.childMaintenance'),

                      'person.income.hasOtherBenefit',
                      {
                        key: 'person.income.otherBenefit',
                        condition: 'model.person.income.hasOtherBenefit'
                      },
                      'person.details.inEducation',
                      {
                        key: 'person.details.educationDetails',
                        condition: 'model.person.details.inEducation'
                      },
                    ]
                  },
                  {
                    type: "fieldset",
                    title: "Your partner's income and benefits",
                    htmlClass: "col-md-6",
                    condition: 'model.person.intro.hasPartner && model.partner.intro.discloseFinances',
                    items: [
                      {
                        type: "help",
                        helpvalue: "<h4 class='section-title'>Wages/Salary</h4>"
                      },
                      {
                        key: 'partner.income.hasWage',
                        title: 'Is your partner currently employed?'
                      },
                      {
                        key: 'partner.income.wages',
                        condition: 'model.partner.income.hasWage',
                        add: "Add salary/wage",
                        title: 'Wages/Salary',
                        validationMessage: 'Enter details of at least one employer'
                      },
                      {
                        type: "help",
                        helpvalue: "<h4 class='section-title'>Private/Works Pension or Annuity</h4>"
                      },
                      {
                        key: 'partner.income.hasPrivatePension',
                        title: 'Is your partner in receipt of private/works pension or annuity?'
                      },
                      {
                        key: 'partner.income.privatePensions',
                        condition: 'model.partner.income.hasPrivatePension',
                        add: "Add more",
                        title: 'Private/Works Pensions',
                        validationMessage: 'Enter details of at least one private/works pension'
                      },

                      {
                        key: 'partner.income.hasUniversalCredit',
                        title: 'Is your partner in receipt of Universal Credit?'
                      },
                      {
                        key: 'partner.income.universalCredit',
                        notitle: true,
                        condition: 'model.partner.income.hasUniversalCredit'
                      },
                      {
                        key: 'partner.income.universalCreditBreakdown',
                        notitle: true,
                        title: 'Universal Credit Allowances',
                        condition: 'model.partner.income.hasUniversalCredit'
                      },


                      periodicPaymentField('partner.income.retirementPension'),

                      fieldDefinitionService.groups.periodicJointPaymentFormFields({key: 'partner.income.incomeSupportPayment'}, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),
                      periodicPaymentField('partner.income.incapacityBenefit'),
                      fieldDefinitionService.groups.periodicJointPaymentFormFields({key: 'partner.income.employmentSupport'}, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),
                      {
                        key: 'partner.income.employmentSupportGroup',
                        condition: 'model.person.intro.hasPartner && model.partner.income.employmentSupport'
                      },
                      fieldDefinitionService.groups.periodicJointPaymentFormFields({key: 'partner.income.jobSeekersAllowance'}, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),

                      periodicPaymentField('partner.income.industrialInjuriesBenefit'),
                      fieldDefinitionService.groups.periodicJointPaymentFormFields({key: 'partner.income.rentalIncome'}, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),
                      fieldDefinitionService.groups.periodicJointPaymentFormFields({key: 'partner.income.workingTaxCredit'}, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),
                      fieldDefinitionService.groups.periodicJointPaymentFormFields({key: 'partner.income.childTaxCredit'}, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),
                      periodicPaymentField('partner.income.maintenance'),
                      periodicPaymentField('partner.income.warPension'),
                      periodicPaymentField('partner.income.warWidowsPension'),
                      periodicPaymentField('partner.income.warSpecialPayment'),
                      fieldDefinitionService.groups.periodicJointPaymentFormFields({key: 'partner.income.childBenefit'}, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),
                      periodicPaymentField('partner.income.carersAllowance'),

                      paymentField('partner.income.attendanceAllowance', {
                        condition: conditions.toString('partner.income.attendanceAllowance')
                      }),
                      paymentField('partner.income.dlaCare', {
                        condition: conditions.toString('partner.income.dlaCare')
                      }),
                      paymentField('partner.income.pipDailyLiving', {
                        condition: conditions.toString('partner.income.pipDailyLiving')
                      }),

                      paymentField('partner.income.mobilityAllowance'),
                      fieldDefinitionService.groups.periodicJointPaymentFormFields({key: 'partner.income.pensionGuaranteeCredit'}, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),

                      {
                        key: 'partner.income.pgcIncludesSDP',
                        condition: 'model.partner.income.pensionGuaranteeCredit'
                      },

                      fieldDefinitionService.groups.periodicJointPaymentFormFields({key: 'partner.income.pensionSavingsCredit'}, {}, {
                        condition: 'model.person.intro.hasPartner'
                      }),

                      periodicPaymentField('partner.income.charitableIncome'),

                      periodicPaymentField('partner.income.housingBenefit'),

                      periodicPaymentField('partner.income.childMaintenance'),

                      'partner.income.hasOtherBenefit',
                      {
                        key: 'partner.income.otherBenefit',
                        condition: 'model.partner.income.hasOtherBenefit'
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            type: 'section',
            condition: 'model.page === 5',
            page: 5,
            items: [
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: 'Your money or investments',
                    htmlClass: "col-md-4",
                    items: [
                      {
                        type: 'template',
                        htmlClass: 'divTitle',
                        templateUrl: 'modules/forms/scripts/services/forms/fca_animations/templates/cfa_animation_03_capital-intro.html'
                      },
                      {
                        key: 'person.capital.hasSavingsInvestments',
                        title: 'Do you have any money or investments which are held in accounts where you are the sole named account holder?'
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    condition: 'model.person.intro.hasPartner && model.partner.intro.discloseFinances',
                    title: "Your partner's money or investments",
                    htmlClass: "col-md-4",
                    items: [
                      {
                        key: 'partner.capital.hasSavingsInvestments',
                        title: 'Does your partner have any money or investments which are held in accounts where they are the sole named account holder?'
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    title: "Joint money or investments",
                    htmlClass: "col-md-4",
                    items: [
                      {
                        key: 'joint.capital.hasSavingsInvestments',
                        title: 'Do you have any money or investments which are held in accounts where you and someone else are both named account holders?',
                        description: 'Only include joint accounts where the amount is owned by more than one person. Accounts with additional names for access-only reasons are treated as single owner accounts.'
                      }
                    ]
                  }
                ]
              },
              {
                type: 'template',
                templateUrl: 'modules/forms/scripts/services/forms/DT_FCA/templates/capitalDescription.html'
              },
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    htmlClass: "col-md-4",
                    items: [
                      {
                        condition: 'model.person.capital.hasSavingsInvestments',
                        key: 'person.capital.savingsInvestments',
                        add: "Add account",
                        title: 'Give details of your savings and investments',
                        validationMessage: 'Enter details of at least one account'
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    condition: 'model.person.intro.hasPartner && model.partner.intro.discloseFinances',
                    htmlClass: "col-md-4",
                    items: [
                      {
                        condition: 'model.partner.capital.hasSavingsInvestments',
                        key: 'partner.capital.savingsInvestments',
                        add: "Add account",
                        title: "Give details of your partner's savings and investments",
                        validationMessage: 'Enter details of at least one account'
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    htmlClass: "col-md-4",
                    items: [
                      {
                        condition: 'model.joint.capital.hasSavingsInvestments',
                        key: 'joint.capital.savingsInvestments',
                        add: "Add account",
                        title: 'Give details of jointly owned savings and investments',
                        validationMessage: 'Enter details of at least one account'
                      }
                    ]
                  }
                ]
              },
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: 'Your shareholdings',
                    htmlClass: "col-md-4",
                    items: [
                      {
                        key: 'person.capital.hasShareholdings',
                        title: 'Are you the sole owner of any company shares?'
                      },
                      {
                        key: 'person.capital.shareholdings',
                        condition: 'model.person.capital.hasShareholdings',
                        add: "Add shareholding",
                        title: 'Give details of your shareholdings',
                        validationMessage: 'Enter details of at least one shareholding'
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    condition: 'model.person.intro.hasPartner && model.partner.intro.discloseFinances',
                    title: "Your partner's shareholdings",
                    htmlClass: "col-md-4",
                    items: [
                      {
                        key: 'partner.capital.hasShareholdings',
                        title: 'Is your partner the sole owner of any company shares?'
                      },
                      {
                        key: 'partner.capital.shareholdings',
                        condition: 'model.partner.capital.hasShareholdings',
                        add: "Add shareholding",
                        title: "Give details of your partner's shareholdings",
                        validationMessage: 'Enter details of at least one shareholding'
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    title: "Joint shareholdings",
                    htmlClass: "col-md-4",
                    items: [
                      {
                        key: 'joint.capital.hasShareholdings',
                        title: 'Do you and someone else jointly own any company shares?'
                      },
                      {
                        key: 'joint.capital.shareholdings',
                        condition: 'model.joint.capital.hasShareholdings',
                        add: "Add shareholding",
                        title: 'Give details of jointly owned shareholdings',
                        validationMessage: 'Enter details of at least one shareholding'
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 6',
            page: 6,
            title: 'Property/Land you own or rent',
            items: [
              'person.property.ownership',
              {
                key: 'person.property.jointPropertyDetails',
                condition: 'model.person.property.ownership === "ownedJointly"',
                title: 'Joint Property Details'
              },
              'person.property.propertyType',
              {
                key: 'person.property.exercisedRightToBuy',
                title: 'Did you purchase your property under the Right to Buy scheme?',
                condition: 'model.person.property.ownership === "ownedByYou" || model.person.property.ownership === "ownedByPartner" || model.person.property.ownership === "ownedJointly"'
              },
              {
                key: 'person.property.rightToBuyInformation',
                condition: 'model.person.property.exercisedRightToBuy'
              },
              {
                key: 'person.property.otherProperty',
                condition: 'model.person.property.ownership === "other"'
              },
              {
                key: 'person.property.value',
                description: "Enter the approximate value of your property if it is owned.",
                condition: conditions.toString('person.property.value')
              },
              'person.property.hasAdditionalProperties',
              {
                key: 'person.property.additionalProperties',
                condition: 'model.person.property.hasAdditionalProperties',
                add: "Add property",
                title: 'We need to know value(s) of any property/properties, including land, owned and/or jointly owned by you in this country or abroad other than your main home.',
                validationMessage: 'Enter details of at least one property'
              },
              'person.property.additionalInformation'
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 7',
            page: 7,
            title: 'Sale or Transfer of property, money or investments',
            items: [
              'deprivationOfAssets.hasDisposedOfAssets',
              {
                key: 'deprivationOfAssets.disposedAssets.assetValue',
                condition: 'model.deprivationOfAssets.hasDisposedOfAssets'
              },
              {
                key: 'deprivationOfAssets.disposedAssets.dateOfDisposal',
                condition: 'model.deprivationOfAssets.hasDisposedOfAssets'
              },
              {
                key: 'deprivationOfAssets.disposedAssets.assetsDisposed',
                condition: 'model.deprivationOfAssets.hasDisposedOfAssets'
              },
              {
                key: 'deprivationOfAssets.disposedAssets.reasonForDisposal',
                condition: 'model.deprivationOfAssets.hasDisposedOfAssets'
              },
              {
                type: 'help',
                helpvalue: "<div class='alert alert-info html-view-remove'>Your assessed contribution towards your care is subject to review and may change once Dorset Council have checked the information you've provided. If required, a member of Dorset Council's Financial Assessment Team will contact you in due course.</div",
                condition: 'model.deprivationOfAssets.hasDisposedOfAssets'
              },
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 8',
            page: 8,
            title: 'Household Expenditure',
            items: [
              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    htmlClass: "col-md-8",
                    items: [
                      {
                        type: 'template',
                        htmlClass: 'divTitle',
                        templateUrl: 'modules/forms/scripts/services/forms/fca_animations/templates/cfa_animation_05_outgoings-intro.html'
                      },
                      fullWidthPeriodicPaymentField('person.expenses.mortgageLessHousing', {}, '!model.person.income.hasUniversalCredit'),
                      fullWidthPeriodicPaymentField('person.expenses.mortgage', {}, 'model.person.income.hasUniversalCredit'),

                      fullWidthPeriodicPaymentField('person.expenses.rentLessHousing', {}, '!model.person.income.hasUniversalCredit'),
                      fullWidthPeriodicPaymentField('person.expenses.rent', {}, 'model.person.income.hasUniversalCredit'),

                      paymentField('person.expenses.councilTax'),
                      {
                        type: 'template',
                        htmlClass: 'divTitle',
                        templateUrl: 'modules/forms/scripts/services/forms/DT_FCA/templates/councilTaxDescription.html'
                      },

                      fullWidthPeriodicPaymentField('person.expenses.endowmentInsurance'),

                      fullWidthPeriodicPaymentField('person.expenses.serviceCharge'),

                      fullWidthPeriodicPaymentField('person.expenses.groundRent'),

                      fullWidthPeriodicPaymentField('person.expenses.homeInsurance'),

                      {
                        key: 'person.expenses.isHomeInsuranceCombined',
                        condition: 'model.person.expenses.homeInsurance'
                      },

                      fullWidthPeriodicPaymentField('person.expenses.compulsoryCourtPayments'),

                      'person.expenses.hasAdditionalExpenses',

                      {
                        condition: 'model.person.expenses.hasAdditionalExpenses',
                        key: 'person.expenses.additionalExpenses'
                      }

                    ]
                  }
                ]
              }
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 9',
            page: 9,
            title: 'Disability-Related Expenses',
            items: [
              'person.intro.excludeNonResidentialCaretypes',
              {
                type: 'fieldset',
                condition: 'model.person.intro.excludeNonResidentialCaretypes === false',
                items: [
                  {
                    type: "template",
                    templateUrl: "modules/forms/scripts/services/forms/DT_FCA/templates/dre.html"
                  },

                  fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.fuel', {
                    description: 'Enter the cost of gas, electricity or oil used to heat your home. This will be compared with the average for the type of property you live in, with the national average. An allowance will be made where your actual cost exceeds the national average cost.'
                  }),
                  paymentField('person.expenses.disabilityRelated.gardening', {
                    description: 'Enter the cost of basic maintenance if you live alone and you are unable to maintain your garden yourself. Gardening costs usually occur over 6 months of the year, please record your annual cost in this field.'
                  }),
                  fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.domesticHelp', {
                    description: 'Enter the cost of professional help for cleaning, cooking or shopping services, where evidence exists of you receiving these service and no one else in the household can perform these tasks for you. Payments to close relatives and partners are not allowed.'
                  }),

                  fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.chiropodist'),
                  fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.waterRates', {
                    description: 'Enter the cost of metered water only. This will be compared with the average for the type of property you live in, with the national average. An allowance will be made where your actual cost exceeds the national average.'
                  }),
                  paymentField('person.expenses.disabilityRelated.washingPerWeek'),
                  paymentField('person.expenses.disabilityRelated.poweredBed', {
                    description: 'Enter the cost of any privately purchased items that are for your sole use only.'
                  }),

                  paymentField('person.expenses.disabilityRelated.bedTurning', {
                    description: 'Enter the cost of any privately purchased items that are for your sole use only.'
                  }),
                  paymentField('person.expenses.disabilityRelated.mobilityScooter', {
                    description: 'Enter the cost of any privately purchased items that are for your sole use only.'
                  }),

                  paymentField('person.expenses.disabilityRelated.stairLift', {
                    description: 'Enter the cost of any privately purchased items that are for your sole use only.'
                  }),
                  paymentField('person.expenses.disabilityRelated.hoistPurchase', {
                    description: 'Enter the cost of any privately purchased items that are for your sole use only.'
                  }),
                  paymentField('person.expenses.disabilityRelated.manualWheelchairPurchase', {
                    description: 'Enter the cost of any privately purchased items that are for your sole use only.'
                  }),
                  paymentField('person.expenses.disabilityRelated.poweredWheelchairPurchase', {
                    description: 'Enter the cost of any privately purchased items that are for your sole use only.'
                  }),
                  fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.specialClothes', {
                    description: 'Enter additional cost of clothing purchased due to weight loss/gain or excessive wear and tear caused by illness or disability. Include any additional cost of specialist footwear when compared to non-specialist.'
                  }),
                  fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.equipment'),
                  fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.alarm', {
                    description: 'Enter the cost you pay unless it is included in your Housing Benefit payment or it is a Tele-care service provided by Adult Social Care.'
                  }),
                  fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.incontinenceAids', {
                    description: 'Enter the amount you spend on non NHS products.',
                  }),

                  fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.additionalBedding', {
                    description: 'Enter the amount you spend on extra bedding due to wear and tear or incontinence, over and above anything provided to you by the NHS'
                  }),

                  paymentField('person.expenses.disabilityRelated.poweredRecliningChair', {
                    description: 'Enter the cost of any privately purchased items that are for your sole use only.'
                  }),

                  fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.privateCare'),
                  fullWidthPeriodicPaymentField('person.expenses.disabilityRelated.prescription'),

                  'person.expenses.disabilityRelated.hasOtherDRE',
                  {
                    type: 'template',
                    templateUrl: 'modules/forms/scripts/services/forms/DT_FCA/templates/dreGuidance.html',
                    condition: 'model.person.expenses.disabilityRelated.hasOtherDRE'
                  },
                  {
                    key: 'person.expenses.disabilityRelated.otherDRE',
                    condition: 'model.person.expenses.disabilityRelated.hasOtherDRE'
                  },
                  {
                    key: 'person.expenses.disabilityRelated.expensesAboveMaxThreshold',
                    htmlClass: 'invisible'
                  }
                ]
              }
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 10',
            page: 10,
            title: 'Other information',
            items: [
              'otherInformation'
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 11',
            page: 11,
            title: 'Declaration',
            items: [
              {
                type: "template",
                templateUrl: "modules/forms/scripts/services/forms/DT_FCA/templates/declaration-intro.html"
              },
              {
                type: "template",
                templateUrl: "modules/forms/scripts/services/forms/DT_FCA/templates/declaration.html"
              },
              'declarations.declaration'
            ]
          },
          {
            type: 'fieldset',
            condition: 'model.page === 12',
            page: 12,
            title: 'Provisional cost of care',
            items: [
              {
                type: 'template',
                templateUrl: 'modules/forms/scripts/services/forms/DT_FCA/templates/costOfCareIntro.html'
              },
              {
                type: 'template',
                templateUrl: 'modules/forms/scripts/services/forms/DT_FCA/templates/costOfCareImportantInfo.html'
              },

              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: 'Non-residential',
                    htmlClass: "col-md-6",
                    condition: conditions.toString('person.intro.excludeNonResidentialCaretypes') === true,
                    items: [
                      {
                        key: 'careAssessment.nonResidential.maxContribution',
                        type: 'careAssessmentResult',
                        formTypeCode: 'DT_FCA',
                        calculator: careAssessmentCalculator,
                        careType: constants.careTypes.nonResidential,
                        dreKey: 'person.expenses.disabilityRelated.expensesAboveMaxThreshold',
                        title: 'Maximum non-residential care contribution',
                      },
                      {
                        type: "fieldset",
                        htmlClass: 'margin-top20 collapsible',
                        notitle: true,
                        collapse: true,
                        items: ['careAssessment.nonResidential.workings']
                      }
                    ]
                  }
                ]
              },

              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: 'Temporary Residential (Weeks 1-4)',
                    htmlClass: "col-md-6",
                    items: [
                      {
                        key: 'careAssessment.tempWeek1Residential.maxContribution',
                        type: 'careAssessmentResult',
                        formTypeCode: 'DT_FCA',
                        calculator: careAssessmentCalculator,
                        careType: constants.careTypes.tempWeek1Residential,
                        dreKey: 'person.expenses.disabilityRelated.expensesAboveMaxThreshold',
                        title: 'Maximum temporary residential care contribution',
                      },
                      {
                        type: "fieldset",
                        htmlClass: 'margin-top20 collapsible',
                        notitle: true,
                        collapse: true,
                        items: ['careAssessment.tempWeek1Residential.workings']
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    title: 'Temporary Residential (Weeks 5+)',
                    htmlClass: "col-md-6",
                    items: [
                      {
                        key: 'careAssessment.tempWeek5Residential.maxContribution',
                        type: 'careAssessmentResult',
                        formTypeCode: 'DT_FCA',
                        calculator: careAssessmentCalculator,
                        careType: constants.careTypes.tempWeek5Residential,
                        dreKey: 'person.expenses.disabilityRelated.expensesAboveMaxThreshold',
                        title: 'Maximum temporary residential care contribution',
                      },
                      {
                        type: "fieldset",
                        htmlClass: 'margin-top20 collapsible',
                        notitle: true,
                        collapse: true,
                        items: ['careAssessment.tempWeek5Residential.workings']
                      }
                    ]
                  }
                ]
              },


              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: 'Permanent Residential (Weeks 1-4)',
                    htmlClass: "col-md-6",
                    items: [
                      {
                        key: 'careAssessment.permWeek1Residential.maxContribution',
                        type: 'careAssessmentResult',
                        formTypeCode: 'DT_FCA',
                        calculator: careAssessmentCalculator,
                        careType: constants.careTypes.permWeek1Residential,
                        dreKey: 'person.expenses.disabilityRelated.expensesAboveMaxThreshold',
                        title: 'Maximum permanent residential care contribution',
                      },
                      {
                        type: "fieldset",
                        htmlClass: 'margin-top20 collapsible',
                        notitle: true,
                        collapse: true,
                        items: ['careAssessment.permWeek1Residential.workings']
                      }
                    ]
                  },
                  {
                    type: "fieldset",
                    title: 'Permanent Residential (Weeks 5-12)',
                    htmlClass: "col-md-6",
                    items: [
                      {
                        key: 'careAssessment.permWeek5Residential.maxContribution',
                        type: 'careAssessmentResult',
                        formTypeCode: 'DT_FCA',
                        calculator: careAssessmentCalculator,
                        careType: constants.careTypes.permWeek5Residential,
                        dreKey: 'person.expenses.disabilityRelated.expensesAboveMaxThreshold',
                        title: 'Maximum permanent residential care contribution',
                      },
                      {
                        type: "fieldset",
                        htmlClass: 'margin-top20 collapsible',
                        notitle: true,
                        collapse: true,
                        items: ['careAssessment.permWeek5Residential.workings']
                      }
                    ]
                  }
                ]
              },

              {
                type: 'section',
                htmlClass: "row",
                items: [
                  {
                    type: "fieldset",
                    title: 'Permanent Residential (Weeks 13+)',
                    htmlClass: "col-md-6",
                    items: [
                      {
                        key: 'careAssessment.permWeek13Residential.maxContribution',
                        type: 'careAssessmentResult',
                        formTypeCode: 'DT_FCA',
                        calculator: careAssessmentCalculator,
                        careType: constants.careTypes.permWeek13Residential,
                        dreKey: 'person.expenses.disabilityRelated.expensesAboveMaxThreshold',
                        title: 'Maximum permanent residential care contribution',
                      },
                      {
                        type: "fieldset",
                        htmlClass: 'margin-top20 collapsible',
                        notitle: true,
                        collapse: true,
                        items: ['careAssessment.permWeek13Residential.workings']
                      }
                    ]
                  }
                ]
              },

            ]
          },

          {
            type: "section",
            condition: "areFormNavigationButtonsVisible()",
            items: [
              {
                type: "actions",
                htmlClass: "formPaginationButtons",
                items: [
                  {
                    type: "button",
                    style: "btn-default",
                    title: "Previous",
                    onClick: "prev()"
                  },
                  {
                    type: "button",
                    style: "btn-primary",
                    title: "Next",
                    onClick: "next()"
                  }]
              }
            ]
          }
        ]);

        return formUI;
      }]);
