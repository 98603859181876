(function (angular) {

    'use strict';

    /* jshint eqeqeq:false, eqnull:true */

    angular.module('kerp-forms.forms').factory(
      'FCR_CONDITIONS', ['FormConditions', 'dateUtils', function (FormConditions, dateUtils) {

        var service = FormConditions.clone({

          conditions: {
            applicant_othername_other: function () {
              return this.lib.visibleAndIcontains('applicant_othername', 'Yes');
            },
            applicant_movedate: function () {
              return this.lib.visibleAndIcontains('applicant_knownmovedate', 'Yes');
            },
            applicant_fulltime_student: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_student', 'Student') !== -1;
            },
            partner_title: function () {
              return this.lib.visibleAndIcontains('couple', 'Yes');
            },
            partner_forename: ["partner_title"],
            partner_surname: ["partner_title"],
            partner_othername: ["partner_title"],
            partner_othername_other: function () {
              return this.lib.visibleAndIcontains('partner_othername', 'Yes');
            },
            partner_dob: ["partner_title"],
            partner_ni: ["partner_title"],
            partner_mainhome: ["partner_title"],
            partner_mainhome_movedin: function () {
              return this.lib.visibleAndIcontains('partner_mainhome', 'Main home now');
            },
            partner_knownmovedate: function () {
              return this.lib.visibleAndIcontains('partner_mainhome', 'Moving in within the next few weeks');
            },
            partner_movedate: function () {
              return this.lib.visibleAndIcontains('partner_knownmovedate', 'Yes');
            },
            partner_contact: ["partner_title"],
            partner_email: ["partner_title"],
            partner_residentsincedate: function () {
              return this.lib.visibleAndIcontains('partner_arrived_in_last_five', 'Yes');
            },
            partner_previousclaim: ["partner_title"],
            partner_previousclaim_name: function () {
              return this.lib.visibleAndIcontains('partner_previousclaim', 'Yes');
            },
            partner_previousclaim_council: ["partner_previousclaim_name"],
            partner_previousclaim_address: ["partner_previousclaim_name"],
            partner_student: ["partner_title"],
            partner_fulltime_student: function () {
              return this.lib.visibleAndArrayContainsElement('partner_student', 'Student') > -1;
            },
            couple_uc: ["partner_title"],
            applicant_pendingucdate: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_uc', 'Waiting to hear') !== -1;
            },
            couple_pendingucdate: function () {
              return this.lib.visibleAndIcontains('couple_uc', 'We are waiting to hear about our claim');
            },
            applicant_ucvalue: function () {
              return this.lib.visibleAndIcontains('applicant_uc', 'Yes') || this.lib.visibleAndIcontains('couple_uc', 'I get Universal Credit');
            },
            applicant_ucnonworkincome: ["applicant_ucvalue"],
            applicant_uctakehomepay: ["applicant_ucvalue"],
            applicant_uctakenoff: ["applicant_ucvalue"],
            partner_ucvalue: function () {
              return this.lib.visibleAndIcontains('couple_uc', 'My partner gets Universal Credit');
            },
            partner_nonworkincome: function () {
              return this.lib.visibleAndIcontains('couple_uc', 'My partner gets Universal Credit');
            },
            partner_takehomepay: ["partner_ucvalue"],
            partner_takenoff: ["partner_ucvalue"],
            couple_ucvalue: function () {
              return this.lib.visibleAndIcontains('couple_uc', 'We both get Universal Credit');
            },
            couple_nonworkincome: ["couple_ucvalue"],
            couple_takehomepay: ["couple_ucvalue"],
            couple_takenoff: ["couple_ucvalue"],
            applicant_incomesupport: function () {
              return this.lib.visibleAndIcontains('couple_uc', 'Neither of us get Universal Credit') || this.lib.visibleAndIcontains('applicant_uc', 'No');
            },
            applicant_incomesupport_date: function () {
              return this.lib.visibleAndIcontains('applicant_incomesupport', 'Yes');
            },
            applicant_incomesupport_claimdate: function () {
              return this.lib.visibleAndIcontains('applicant_incomesupport', 'I\'m waiting to hear');
            },
            partner_incomesupport: function () {
              return this.lib.visibleAndIcontains('couple_uc', 'Neither of us get Universal Credit');
            },
            partner_incomesupport_date: function () {
              return this.lib.visibleAndIcontains('partner_incomesupport', 'Yes');
            },
            partner_incomesupport_claimdate: function () {
              return this.lib.visibleAndIcontains('partner_incomesupport', 'They are waiting to hear');
            },
            applicant_jsa: function () {
              return this.lib.visibleAndIcontains('applicant_incomesupport', 'No');
            },
            applicant_jsa_date: function () {
              return this.lib.visibleAndIcontains('applicant_jsa', 'Yes');
            },
            applicant_jsa_claimdate: function () {
              return this.lib.visibleAndIcontains('applicant_jsa', 'I\'m waiting to hear');
            },
            partner_jsa: function () {
              return this.lib.visibleAndIcontains('partner_incomesupport', 'No');
            },
            partner_jsa_date: function () {
              return this.lib.visibleAndIcontains('partner_jsa', 'Yes');
            },
            partner_jsa_claimdate: function () {
              return this.lib.visibleAndIcontains('partner_jsa', 'Waiting');
            },
            applicant_incomeesa: function () {
              return this.lib.visibleAndIcontains('applicant_jsa', 'No') || this.lib.visibleAndIcontains('applicant_jsa', 'I\'m waiting to hear');
            },
            applicant_incomeesa_date: function () {
              return this.lib.visibleAndIcontains('applicant_incomeesa', 'Yes');
            },
            applicant_incomeesa_claimdate: function () {
              return this.lib.visibleAndIcontains('applicant_incomeesa', 'I\'m waiting to hear');
            },
            applicant_incomeesarate: ["applicant_incomeesa_date"],
            partner_incomeesa: function () {
              return this.lib.visibleAndIcontains('partner_jsa', 'No') || this.lib.visibleAndIcontains('partner_jsa', 'Waiting');
            },
            partner_incomeesa_date: function () {
              return this.lib.visibleAndIcontains('partner_incomeesa', 'Yes');
            },
            partner_incomeesa_claimdate: function () {
              return this.lib.visibleAndIcontains('partner_incomeesa', 'They are waiting to hear');
            },
            partner_incomeesarate: ["partner_incomeesa_date"],
            applicant_pensioncreditguarantee_date: function () {
              return this.lib.visibleAndIcontains('applicant_pensioncreditguarantee', 'Yes');
            },
            applicant_pensioncreditguarantee_claimdate: function () {
              return this.lib.visibleAndIcontains('applicant_pensioncreditguarantee', 'I\'m waiting to hear');
            },
            partner_pensioncreditguarantee: ["partner_title"],
            partner_pensioncreditguarantee_date: function () {
              return this.lib.visibleAndIcontains('partner_pensioncreditguarantee', 'Yes');
            },
            partner_pensioncreditguarantee_claimdate: function () {
              return this.lib.visibleAndIcontains('partner_pensioncreditguarantee', 'They are waiting to hear');
            },
            applicant_pensioncreditsavings_date: function () {
              return this.lib.visibleAndIcontains('applicant_pensioncreditsavings', 'Yes');
            },
            applicant_pensioncreditsavings_claimdate: function () {
              return this.lib.visibleAndIcontains('applicant_pensioncreditsavings', 'I\'m waiting to hear');
            },
            partner_pensioncreditsavings: ["partner_title"],
            partner_pensioncreditsavings_date: function () {
              return this.lib.visibleAndIcontains('partner_pensioncreditsavings', 'Yes');
            },
            partner_pensioncreditsavings_claimdate: function () {
              return this.lib.visibleAndIcontains('partner_pensioncreditsavings', 'They are waiting to hear');
            },
            applicant_dla: ["applicant_uc"],
            applicant_dlafrequency: function () {
              return this.lib.visibleAndIcontains('applicant_dla', 'Yes');
            },
            applicant_dlacare4weeks: function () {
              return this.lib.visibleAndIcontains('applicant_dlafrequency', 'Every 4 weeks');
            },
            applicant_dlacareweek: function () {
              return this.lib.visibleAndIcontains('applicant_dlafrequency', 'Every week');
            },
            applicant_dlamot4weeks: ["applicant_dlacare4weeks"],
            applicant_dlamotweek: ["applicant_dlacareweek"],
            couple_dla: ["partner_title"],
            applicant_dlafrequency1: function () {
              return this.lib.visibleAndIcontains('couple_dla', 'I get DLA') || this.lib.visibleAndIcontains('couple_dla', 'We both get DLA');
            },
            applicant_dlacare4weeks1: function () {
              return this.lib.visibleAndIcontains('applicant_dlafrequency1', 'Every 4 weeks');
            },
            applicant_dlacareweek1: function () {
              return this.lib.visibleAndIcontains('applicant_dlafrequency1', 'Every week');
            },
            applicant_dlamot4weeks1: ["applicant_dlacare4weeks1"],
            applicant_dlamotweek1: ["applicant_dlacareweek1"],
            partner_dlafrequency: function () {
              return this.lib.visibleAndIcontains('couple_dla', 'My partner gets DLA') || this.lib.visibleAndIcontains('couple_dla', 'We both get DLA');
            },
            partner_dlacare4weeks: function () {
              return this.lib.visibleAndIcontains('partner_dlafrequency', 'Every 4 weeks');
            },
            partner_dlacareweek: function () {
              return this.lib.visibleAndIcontains('partner_dlafrequency', 'Every week');
            },
            partner_dlamot4weeks: ["partner_dlacare4weeks"],
            partner_dlamotweek: ["partner_dlacareweek"],
            applicant_pip: ["applicant_uc"],
            couple_pip: ["partner_title"],
            applicant_pipdaily: function () {
              return this.lib.visibleAndIcontains('applicant_pip', 'Yes') || this.lib.visibleAndIcontains('couple_pip', 'I get PIP') || this.lib.visibleAndIcontains('couple_pip', 'We both get PIP');
            },
            applicant_pipmot: ["applicant_pipdaily"],
            partner_pipdaily: function () {
              return this.lib.visibleAndIcontains('couple_pip', 'My partner gets PIP') || this.lib.visibleAndIcontains('couple_pip', 'We both get PIP');
            },
            partner_pipmot: ["partner_pipdaily"],
            applicant_aa: ["applicant_uc"],
            couple_aa: ["partner_title"],
            applicant_calookingafter: ["applicant_uc"],
            applicant_carername: function () {
              return this.lib.visibleAndIcontains('applicant_calookingafter', 'Yes');
            },
            applicant_careraddress1: ["applicant_carername"],
            applicant_careraddress2: ["applicant_carername"],
            applicant_careraddress3: ["applicant_carername"],
            applicant_carerpostcode: ["applicant_carername"],
            applicant_ca_entitlement: ["applicant_uc"],
            applicant_ca8weeks: ["applicant_uc"],
            applicant_ca: function () {
              return this.lib.visibleAndIcontains('applicant_ca8weeks', 'Yes');
            },

            applicant_cafrequency: function () {
              return this.lib.visibleAndIcontains('applicant_ca', 'Yes');
            },
            applicant_castop: function () {
              return this.lib.visibleAndIcontains('applicant_ca', 'No');
            },
            applicant_carate: ["applicant_cafrequency"],
            couple_calookingafter: ["partner_title"],
            carer_name: function () {
              return this.lib.visibleAndIcontains('couple_calookingafter', 'Yes');
            },
            carer_address1: ["carer_name"],
            carer_address2: ["carer_name"],
            carer_address3: ["carer_name"],
            carer_postcode: ["carer_name"],
            couple_ca_entitlement: ["partner_title"],
            couple_ca8weeks: ["partner_title"],
            applicant_ca1: function () {
              return this.lib.visibleAndIcontains('couple_ca8weeks', 'I have') || this.lib.visibleAndIcontains('couple_ca8weeks', 'Both of us have');
            },

            applicant_cafrequency1: function () {
              return this.lib.visibleAndIcontains('applicant_ca1', 'Yes');
            },
            applicant_castop1: function () {
              return this.lib.visibleAndIcontains('applicant_ca1', 'No');
            },
            applicant_carate1: ["applicant_cafrequency1"],
            partner_ca: function () {
              return this.lib.visibleAndIcontains('couple_ca8weeks', 'My partner has') || this.lib.visibleAndIcontains('couple_ca8weeks', 'Both of us have');
            },
            partner_castop: function () {
              return this.lib.visibleAndIcontains('partner_ca', 'No');
            },
            partner_cafrequency: function () {
              return this.lib.visibleAndIcontains('partner_ca', 'Yes');
            },
            partner_carate: ["partner_cafrequency"],
            partner_disabled: ["partner_title"],
            applicant_livingaway: function () {
              return this.lib.visibleAndIcontains('couple', 'No');
            },
            couple_livingaway: function () {
              return this.lib.visibleAndIcontains('couple', 'Yes');
            },
            applicant_livingaway_reason: function () {
              return this.lib.visibleAndIcontains('applicant_livingaway', 'Yes') || this.lib.visibleAndIcontains('couple_livingaway', 'I am') || this.lib.visibleAndIcontains('couple_livingaway', 'We both are');
            },
            applicant_livingaway_lastlive: ["applicant_livingaway_reason"],
            applicant_livingaway_intendtoreturn: ["applicant_livingaway_reason"],
            applicant_livingaway_returndate: function () {
              return this.lib.visibleAndIcontains('applicant_livingaway_intendtoreturn', 'Yes');
            },
            template_applicant_livingaway_address: ["applicant_livingaway_reason"],
            applicant_livingaway_uprn: ["applicant_livingaway_reason"],
            applicant_livingaway_addressline1: ["applicant_livingaway_reason"],
            applicant_livingaway_addressline2: ["applicant_livingaway_reason"],
            applicant_livingaway_addressline3: ["applicant_livingaway_reason"],
            applicant_livingaway_postcode: ["applicant_livingaway_reason"],
            applicant_livingaway_sublet: ["applicant_livingaway_reason"],
            applicant_livingaway_lives: function () {
              return this.lib.visibleAndIcontains('applicant_livingaway_sublet', 'Yes');
            },
            partner_livingaway_reason: function () {
              return this.lib.visibleAndIcontains('couple_livingaway', 'My partner is') || this.lib.visibleAndIcontains('couple_livingaway', 'We both are');
            },
            partner_livingaway_lastlive: ["partner_livingaway_reason"],
            partner_livingaway_intendtoreturn: ["partner_livingaway_reason"],
            partner_livingaway_returndate: function () {
              return this.lib.visibleAndIcontains('partner_livingaway_intendtoreturn', 'Yes');
            },
            template_partner_livingaway_address: ["partner_livingaway_reason"],
            partner_livingaway_uprn: ["partner_livingaway_reason"],
            partner_livingaway_addressline1: ["partner_livingaway_reason"],
            partner_livingaway_addressline2: ["partner_livingaway_reason"],
            partner_livingaway_addressline3: ["partner_livingaway_reason"],
            partner_livingaway_postcode: ["partner_livingaway_reason"],
            partner_livingaway_sublet: ["partner_livingaway_reason"],
            partner_livingaway_lives: function () {
              return this.lib.visibleAndIcontains('partner_livingaway_sublet', 'Yes');
            },
            partner_pregnant: ["couple_livingaway"],
            applicant_duedate: function () {
              return this.lib.visibleAndIcontains('applicant_pregnant', 'Yes');
            },
            partner_duedate: function () {
              return this.lib.visibleAndIcontains('partner_pregnant', 'Yes');
            },
            couple_dependantchildren: ["couple_livingaway"],
            applicant_dependantchildren: ["applicant_livingaway"],
            dependantchildren_no: function () {
              return this.lib.visibleAndIcontains('couple_dependantchildren', 'Yes') || this.lib.visibleAndIcontains('applicant_dependantchildren', 'Yes');
            },
            other_adultsno: ['has_other_adults'],
            jointtenants: ['has_other_adults'],
            boarders: ['has_other_adults'],
            nondependants: ['has_other_adults'],
            applicant_kind_of_work_se: function () {
              return this.lib.visibleAndIcontains('applicant_self_employed', 'Yes');
            },
            applicant_business_start_se: ["applicant_kind_of_work_se"],
            applicant_business_name_se: ["applicant_kind_of_work_se"],
            applicant_business_addressline1: ["applicant_kind_of_work_se"],
            applicant_business_addressline2: ["applicant_kind_of_work_se"],
            applicant_business_addressline3: ["applicant_kind_of_work_se"],
            applicant_business_postcode_se: ["applicant_kind_of_work_se"],
            applicant_business_partner: ["applicant_kind_of_work_se"],
            applicant_business_partner_name: function () {
              return this.lib.visibleAndIcontains('applicant_business_partner', 'Yes');
            },
            applicant_business_partner_addressline1: ["applicant_business_partner_name"],
            applicant_business_partner_addressline2: ["applicant_business_partner_name", function () {
              return this.lib.getModelVisible('applicant_business_partner_addressline1');
            }],
            applicant_business_partner_addressline3: ["applicant_business_partner_addressline2"],
            applicant_business_partner_postcode: ["applicant_business_partner_name"],
            applicant_self_employed_startup_allow: ["applicant_kind_of_work_se"],
            applicant_self_employed_startup_allow_amount: function () {
              return this.lib.visibleAndIcontains('applicant_self_employed_startup_allow', 'Yes');
            },
            applicant_self_employed_startup_allow_frequency: ["applicant_self_employed_startup_allow_amount"],
            applicant_self_employed_pension: ["applicant_kind_of_work_se"],
            applicant_self_employed_pension_amount: function () {
              return this.lib.visibleAndIcontains('applicant_self_employed_pension', 'Yes');
            },
            applicant_self_employed_pension_frequency: ["applicant_self_employed_pension_amount"],
            partner_self_employed: ["couple_livingaway"],
            partner_kind_of_work_se: function () {
              return this.lib.visibleAndIcontains('partner_self_employed', 'Yes');
            },
            partner_business_start_se: ["partner_kind_of_work_se"],
            partner_business_name_se: ["partner_kind_of_work_se"],
            partner_business_address_se_1: function () {
              return this.lib.visibleAndIcontains('partner_self_employed', 'Yes');
            },
            partner_business_address_se_2: ["partner_business_address_se_1"],
            partner_business_address_se_3: ["partner_business_address_se_1"],
            partner_business_postcode: ["partner_kind_of_work_se"],
            partner_business_partner: ["partner_kind_of_work_se"],
            partner_business_partner_name: function () {
              return this.lib.visibleAndIcontains('partner_business_partner', 'Yes');
            },
            partner_business_partner_addressline1: ["partner_business_partner_name"],
            partner_business_partner_addressline2: ["partner_business_partner_name", function () {
              return this.lib.getModelVisible('partner_business_partner_addressline1');
            }],
            partner_business_partner_addressline3: ["partner_business_partner_addressline2"],
            partner_business_partner_postcode: ["partner_business_partner_name"],
            partner_self_employed_startup_allow: ["partner_kind_of_work_se"],
            partner_self_employed_startup_allow_amount: function () {
              return this.lib.visibleAndIcontains('partner_self_employed_startup_allow', 'Yes');
            },
            partner_self_employed_startup_allow_frequency: ["partner_self_employed_startup_allow_amount"],
            partner_self_employed_pension: ["partner_kind_of_work_se"],
            partner_self_employed_pension_amount: function () {
              return this.lib.visibleAndIcontains('partner_self_employed_pension', 'Yes');
            },
            partner_self_employed_pension_frequency: ["partner_self_employed_pension_amount"],
            applicant_number_of_employers: function () {
              return this.lib.visibleAndIcontains('applicant_employed', 'Yes');
            },

            applicant_employed_pension_number: function () {
              return this.lib.visibleAndIcontains('applicant_employed_pension', 'Yes');
            },

            partner_employed: ["couple_livingaway"],
            partner_number_of_employers: function () {
              return this.lib.visibleAndIcontains('partner_employed', 'Yes');
            },

            partner_employed_pension_number: function () {
              return this.lib.visibleAndIcontains('partner_employed_pension', 'Yes');
            },

            applicant_other_benefit_adoptionpay_status: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_other_benefit_type', 'Adoption Pay') !== -1;
            },
            applicant_other_benefit_adoptionpay_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_adoptionpay_status', 'Getting now');
            },
            applicant_other_benefit_adoptionpay_frequency: ["applicant_other_benefit_adoptionpay_amount"],
            applicant_other_benefit_armedforcescompensation_status: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_other_benefit_type', 'Armed Forces Compensation Scheme (AFCS)') !== -1;
            },
            applicant_other_benefit_armedforcescompensation_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_armedforcescompensation_status', 'Getting now');
            },
            applicant_other_benefit_armedforcescompensation_frequency: ["applicant_other_benefit_armedforcescompensation_amount"],
            applicant_other_benefit_bereavementallowance_status: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_other_benefit_type', 'Bereavement Allowance') !== -1;
            },
            applicant_other_benefit_bereavementallowance_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_bereavementallowance_status', 'Getting now');
            },
            applicant_other_benefit_bereavementallowance_frequency: ["applicant_other_benefit_bereavementallowance_amount"],
            applicant_other_benefit_carersallowance_status: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_other_benefit_type', 'Carer\'s Allowance') !== -1;
            },
            applicant_other_benefit_carersallowance_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_carersallowance_status', 'Getting now');
            },
            applicant_other_benefit_carersallowance_frequency: ["applicant_other_benefit_carersallowance_amount"],
            applicant_other_benefit_childbenefit_status: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_other_benefit_type', 'Child Benefit') !== -1;
            },
            applicant_other_benefit_childbenefit_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_childbenefit_status', 'Getting now');
            },
            applicant_other_benefit_childbenefit_frequency: ["applicant_other_benefit_childbenefit_amount"],
            applicant_other_benefit_childtaxcredit_status: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_other_benefit_type', 'Child Tax Credit') !== -1;
            },
            applicant_other_benefit_childtaxcredit_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_childtaxcredit_status', 'Getting now');
            },
            applicant_other_benefit_childtaxcredit_frequency: ["applicant_other_benefit_childtaxcredit_amount"],
            applicant_other_benefit_contributionesa_status: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_other_benefit_type', 'Contribution-based Employment and Support Allowance') !== -1;
            },
            applicant_other_benefit_contributionesa_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_contributionesa_status', 'Getting now');
            },
            applicant_other_benefit_contributionesa_frequency: ["applicant_other_benefit_contributionesa_amount"],
            applicant_other_benefit_contributionjsa_status: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_other_benefit_type', 'Contribution-based Jobseeker\'s Allowance') !== -1;
            },
            applicant_other_benefit_contributionjsa_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_contributionjsa_status', 'Getting now');
            },
            applicant_other_benefit_contributionjsa_frequency: ["applicant_other_benefit_contributionjsa_amount"],
            applicant_other_benefit_fosteringallowance_status: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_other_benefit_type', 'Fostering Allowance') !== -1;
            },
            applicant_other_benefit_fosteringallowance_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_fosteringallowance_status', 'Getting now');
            },
            applicant_other_benefit_fosteringallowance_frequency: ["applicant_other_benefit_fosteringallowance_amount"],
            applicant_other_benefit_guardiansallowance_status: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_other_benefit_type', 'Guardian\'s Allowance') !== -1;
            },
            applicant_other_benefit_guardiansallowance_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_guardiansallowance_status', 'Getting now');
            },
            applicant_other_benefit_guardiansallowance_frequency: ["applicant_other_benefit_guardiansallowance_amount"],
            applicant_other_benefit_incapacitybenefit_status: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_other_benefit_type', 'Incapacity Benefit') !== -1;
            },
            applicant_other_benefit_incapacitybenefit_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_incapacitybenefit_status', 'Getting now');
            },
            applicant_other_benefit_incapacitybenefit_frequency: ["applicant_other_benefit_incapacitybenefit_amount"],
            applicant_other_benefit_industrialdeathbenefit_status: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_other_benefit_type', 'Industrial Death Benefit') !== -1;
            },
            applicant_other_benefit_industrialdeathbenefit_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_industrialdeathbenefit_status', 'Getting now');
            },
            applicant_other_benefit_industrialdeathbenefit_frequency: ["applicant_other_benefit_industrialdeathbenefit_amount"],
            applicant_other_benefit_injuriesdisablementbenefit_status: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_other_benefit_type', 'Industrial Injuries Disablement Benefit') !== -1;
            },
            applicant_other_benefit_injuriesdisablementbenefit_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_injuriesdisablementbenefit_status', 'Getting now');
            },
            applicant_other_benefit_injuriesdisablementbenefit_frequency: ["applicant_other_benefit_injuriesdisablementbenefit_amount"],
            applicant_other_benefit_maintenancepayment_status: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_other_benefit_type', 'Maintenance Payment') !== -1;
            },
            applicant_other_benefit_maintenancepayment_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_maintenancepayment_status', 'Getting now');
            },
            applicant_other_benefit_maintenancepayment_frequency: ["applicant_other_benefit_maintenancepayment_amount"],
            applicant_other_benefit_maternityallowance_status: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_other_benefit_type', 'Maternity Allowance') !== -1;
            },
            applicant_other_benefit_maternityallowance_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_maternityallowance_status', 'Getting now');
            },
            applicant_other_benefit_maternityallowance_frequency: ["applicant_other_benefit_maternityallowance_amount"],
            applicant_other_benefit_severedisablementallowance_status: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_other_benefit_type', 'Severe Disablement Allowance') !== -1;
            },
            applicant_other_benefit_severedisablementallowance_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_severedisablementallowance_status', 'Getting now');
            },
            applicant_other_benefit_severedisablementallowance_frequency: ["applicant_other_benefit_severedisablementallowance_amount"],
            applicant_other_benefit_stateretirementpension_status: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_other_benefit_type', 'State Retirement Pension') !== -1;
            },
            applicant_other_benefit_stateretirementpension_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_stateretirementpension_status', 'Getting now');
            },
            applicant_other_benefit_stateretirementpension_frequency: ["applicant_other_benefit_stateretirementpension_amount"],
            applicant_other_benefit_statutorymaternitypay_status: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_other_benefit_type', 'Statutory Maternity Pay') !== -1;
            },
            applicant_other_benefit_statutorymaternitypay_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_statutorymaternitypay_status', 'getting now');
            },
            applicant_other_benefit_statutorymaternitypay_frequency: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_statutorymaternitypay_status', 'Getting now');
            },
            applicant_other_benefit_statutorypaternitypay_status: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_other_benefit_type', 'Statutory Paternity Pay') !== -1;
            },
            applicant_other_benefit_statutorypaternitypay_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_statutorypaternitypay_status', 'Getting now');
            },
            applicant_other_benefit_statutorypaternitypay_frequency: ["applicant_other_benefit_statutorypaternitypay_amount"],
            applicant_other_benefit_statutorysickpay_status: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_other_benefit_type', 'Statutory Sick Pay') !== -1;
            },
            applicant_other_benefit_statutorysickpay_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_statutorysickpay_status', 'Getting now');
            },
            applicant_other_benefit_statutorysickpay_frequency: ["applicant_other_benefit_statutorysickpay_amount"],
            applicant_other_benefit_trainingallowance_status: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_other_benefit_type', 'Training Allowance') !== -1;
            },
            applicant_other_benefit_trainingallowance_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_trainingallowance_status', 'Getting now');
            },
            applicant_other_benefit_trainingallowance_frequency: ["applicant_other_benefit_trainingallowance_amount"],
            applicant_other_benefit_wardisablementbenefit_status: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_other_benefit_type', 'War Disablement Benefit') !== -1;
            },
            applicant_other_benefit_wardisablementbenefit_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_wardisablementbenefit_status', 'Getting now');
            },
            applicant_other_benefit_wardisablementbenefit_frequency: ["applicant_other_benefit_wardisablementbenefit_amount"],
            applicant_other_benefit_warpension_status: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_other_benefit_type', 'War Pension or War Widow\'s Pension') !== -1;
            },
            applicant_other_benefit_warpension_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_warpension_status', 'Getting now');
            },
            applicant_other_benefit_warpension_frequency: ["applicant_other_benefit_warpension_amount"],
            applicant_other_benefit_widowbenefit_status: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_other_benefit_type', 'Widow or Widower\'s Benefit') !== -1;
            },
            applicant_other_benefit_widowbenefit_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_widowbenefit_status', 'Getting now');
            },
            applicant_other_benefit_widowbenefit_frequency: ["applicant_other_benefit_widowbenefit_amount"],
            applicant_other_benefit_widowedparentsallowance_status: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_other_benefit_type', 'Widowed Parent\'s Allowance') !== -1;
            },
            applicant_other_benefit_widowedparentsallowance_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_widowedparentsallowance_status', 'Getting now');
            },
            applicant_other_benefit_widowedparentsallowance_frequency: ["applicant_other_benefit_widowedparentsallowance_amount"],
            applicant_other_benefit_workingtaxcredit_status: function () {
              return this.lib.visibleAndArrayContainsElement('applicant_other_benefit_type', 'Working Tax Credit') !== -1;
            },
            applicant_other_benefit_workingtaxcredit_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_workingtaxcredit_status', 'Getting now');
            },
            applicant_other_benefit_workingtaxcredit_frequency: ["applicant_other_benefit_workingtaxcredit_amount"],
            partner_other_benefit_type: function () {
              return this.lib.visibleAndIcontains('couple', 'Yes');
            },
            partner_other_benefit_adoptionpay_status: function () {
              return this.lib.visibleAndArrayContainsElement('partner_other_benefit_type', 'Adoption Pay') !== -1;
            },
            partner_other_benefit_adoptionpay_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_adoptionpay_status', 'Getting now');
            },
            partner_other_benefit_adoptionpay_frequency: ["partner_other_benefit_adoptionpay_amount"],
            partner_other_benefit_armedforcescompensation_status: function () {
              return this.lib.visibleAndArrayContainsElement('partner_other_benefit_type', 'Armed Forces Compensation Scheme (AFCS)') !== -1;
            },
            partner_other_benefit_armedforcescompensation_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_armedforcescompensation_status', 'Getting now');
            },
            partner_other_benefit_armedforcescompensation_frequency: ["partner_other_benefit_armedforcescompensation_amount"],
            partner_other_benefit_bereavementallowance_status: function () {
              return this.lib.visibleAndArrayContainsElement('partner_other_benefit_type', 'Bereavement Allowance') !== -1;
            },
            partner_other_benefit_bereavementallowance_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_bereavementallowance_status', 'Getting now');
            },
            partner_other_benefit_bereavementallowance_frequency: ["partner_other_benefit_bereavementallowance_amount"],
            partner_other_benefit_carersallowance_status: function () {
              return this.lib.visibleAndArrayContainsElement('partner_other_benefit_type', 'Carer\'s Allowance') !== -1;
            },
            partner_other_benefit_carersallowance_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_carersallowance_status', 'Getting now');
            },
            partner_other_benefit_carersallowance_frequency: ["partner_other_benefit_carersallowance_amount"],
            partner_other_benefit_childbenefit_status: function () {
              return this.lib.visibleAndArrayContainsElement('partner_other_benefit_type', 'Child Benefit') !== -1;
            },
            partner_other_benefit_childbenefit_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_childbenefit_status', 'Getting now');
            },
            partner_other_benefit_childbenefit_frequency: ["partner_other_benefit_childbenefit_amount"],
            partner_other_benefit_childtaxcredit_status: function () {
              return this.lib.visibleAndArrayContainsElement('partner_other_benefit_type', 'Child Tax Credit') !== -1;
            },
            partner_other_benefit_childtaxcredit_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_childtaxcredit_status', 'Getting now');
            },
            partner_other_benefit_childtaxcredit_frequency: ["partner_other_benefit_childtaxcredit_amount"],
            partner_other_benefit_contributionesa_status: function () {
              return this.lib.visibleAndArrayContainsElement('partner_other_benefit_type', 'Contribution-based Employment and Support Allowance') !== -1;
            },
            partner_other_benefit_contributionesa_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_contributionesa_status', 'Getting now');
            },
            partner_other_benefit_contributionesa_frequency: ["partner_other_benefit_contributionesa_amount"],
            partner_other_benefit_contributionjsa_status: function () {
              return this.lib.visibleAndArrayContainsElement('partner_other_benefit_type', 'Contribution-based Jobseeker\'s Allowance') !== -1;
            },
            partner_other_benefit_contributionjsa_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_contributionjsa_status', 'Getting now');
            },
            partner_other_benefit_contributionjsa_frequency: ["partner_other_benefit_contributionjsa_amount"],
            partner_other_benefit_fosteringallowance_status: function () {
              return this.lib.visibleAndArrayContainsElement('partner_other_benefit_type', 'Fostering Allowance') !== -1;
            },
            partner_other_benefit_fosteringallowance_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_fosteringallowance_status', 'Getting now');
            },
            partner_other_benefit_fosteringallowance_frequency: ["partner_other_benefit_fosteringallowance_amount"],
            partner_other_benefit_guardiansallowance_status: function () {
              return this.lib.visibleAndArrayContainsElement('partner_other_benefit_type', 'Guardian\'s Allowance') !== -1;
            },
            partner_other_benefit_guardiansallowance_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_guardiansallowance_status', 'Getting now');
            },
            partner_other_benefit_guardiansallowance_frequency: ["partner_other_benefit_guardiansallowance_amount"],
            partner_other_benefit_incapacitybenefit_status: function () {
              return this.lib.visibleAndArrayContainsElement('partner_other_benefit_type', 'Incapacity Benefit') !== -1;
            },
            partner_other_benefit_incapacitybenefit_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_incapacitybenefit_status', 'Getting now');
            },
            partner_other_benefit_incapacitybenefit_frequency: ["partner_other_benefit_incapacitybenefit_amount"],
            partner_other_benefit_industrialdeathbenefit_status: function () {
              return this.lib.visibleAndArrayContainsElement('partner_other_benefit_type', 'Industrial Death Benefit') !== -1;
            },
            partner_other_benefit_industrialdeathbenefit_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_industrialdeathbenefit_status', 'Getting now');
            },
            partner_other_benefit_industrialdeathbenefit_frequency: ["partner_other_benefit_industrialdeathbenefit_amount"],
            partner_other_benefit_injuriesdisablementbenefit_status: function () {
              return this.lib.visibleAndArrayContainsElement('partner_other_benefit_type', 'Industrial Injuries Disablement Benefit') !== -1;
            },
            partner_other_benefit_injuriesdisablementbenefit_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_injuriesdisablementbenefit_status', 'Getting now');
            },
            partner_other_benefit_injuriesdisablementbenefit_frequency: ["partner_other_benefit_injuriesdisablementbenefit_amount"],
            partner_other_benefit_maintenancepayment_status: function () {
              return this.lib.visibleAndArrayContainsElement('partner_other_benefit_type', 'Maintenance Payment') !== -1;
            },
            partner_other_benefit_maintenancepayment_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_maintenancepayment_status', 'Getting now');
            },
            partner_other_benefit_maintenancepayment_frequency: ["partner_other_benefit_maintenancepayment_amount"],
            partner_other_benefit_maternityallowance_status: function () {
              return this.lib.visibleAndArrayContainsElement('partner_other_benefit_type', 'Maternity Allowance') !== -1;
            },
            partner_other_benefit_maternityallowance_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_maternityallowance_status', 'Getting now');
            },
            partner_other_benefit_maternityallowance_frequency: ["partner_other_benefit_maternityallowance_amount"],
            partner_other_benefit_severedisablementallowance_status: function () {
              return this.lib.visibleAndArrayContainsElement('partner_other_benefit_type', 'Severe Disablement Allowance') !== -1;
            },
            partner_other_benefit_severedisablementallowance_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_severedisablementallowance_status', 'Getting now');
            },
            partner_other_benefit_severedisablementallowance_frequency: ["partner_other_benefit_severedisablementallowance_amount"],
            partner_other_benefit_stateretirementpension_status: function () {
              return this.lib.visibleAndArrayContainsElement('partner_other_benefit_type', 'State Retirement Pension') !== -1;
            },
            partner_other_benefit_stateretirementpension_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_stateretirementpension_status', 'Getting now');
            },
            partner_other_benefit_stateretirementpension_frequency: ["partner_other_benefit_stateretirementpension_amount"],
            partner_other_benefit_statutorymaternitypay_status: function () {
              return this.lib.visibleAndArrayContainsElement('partner_other_benefit_type', 'Statutory Maternity Pay') !== -1;
            },
            partner_other_benefit_statutorymaternitypay_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_statutorymaternitypay_status', 'Getting now');
            },
            partner_other_benefit_statutorymaternitypay_frequency: ["partner_other_benefit_statutorymaternitypay_amount"],
            partner_other_benefit_statutorypaternitypay_status: function () {
              return this.lib.visibleAndArrayContainsElement('partner_other_benefit_type', 'Statutory Paternity Pay') !== -1;
            },
            partner_other_benefit_statutorypaternitypay_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_statutorypaternitypay_status', 'Getting now');
            },
            partner_other_benefit_statutorypaternitypay_frequency: ["partner_other_benefit_statutorypaternitypay_amount"],
            partner_other_benefit_statutorysickpay_status: function () {
              return this.lib.visibleAndArrayContainsElement('partner_other_benefit_type', 'Statutory Sick Pay') !== -1;
            },
            partner_other_benefit_statutorysickpay_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_statutorysickpay_status', 'Getting now');
            },
            partner_other_benefit_statutorysickpay_frequency: ["partner_other_benefit_statutorysickpay_amount"],
            partner_other_benefit_trainingallowance_status: function () {
              return this.lib.visibleAndArrayContainsElement('partner_other_benefit_type', 'Training Allowance') !== -1;
            },
            partner_other_benefit_trainingallowance_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_trainingallowance_status', 'Getting now');
            },
            partner_other_benefit_trainingallowance_frequency: ["partner_other_benefit_trainingallowance_amount"],
            partner_other_benefit_wardisablementbenefit_status: function () {
              return this.lib.visibleAndArrayContainsElement('partner_other_benefit_type', 'War Disablement Benefit') !== -1;
            },
            partner_other_benefit_wardisablementbenefit_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_wardisablementbenefit_status', 'Getting now');
            },
            partner_other_benefit_wardisablementbenefit_frequency: ["partner_other_benefit_wardisablementbenefit_amount"],
            partner_other_benefit_warpension_status: function () {
              return this.lib.visibleAndArrayContainsElement('partner_other_benefit_type', 'War Pension or War Widow\'s Pension') !== -1;
            },
            partner_other_benefit_warpension_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_warpension_status', 'Getting now');
            },
            partner_other_benefit_warpension_frequency: ["partner_other_benefit_warpension_amount"],
            partner_other_benefit_widowbenefit_status: function () {
              return this.lib.visibleAndArrayContainsElement('partner_other_benefit_type', 'Widow or Widower\'s Benefit') !== -1;
            },
            partner_other_benefit_widowbenefit_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_widowbenefit_status', 'Getting now');
            },
            partner_other_benefit_widowbenefit_frequency: ["partner_other_benefit_widowbenefit_amount"],
            partner_other_benefit_widowedparentsallowance_status: function () {
              return (this.lib.visibleAndArrayContainsElement('partner_other_benefit_type', 'Widowed Parent\'s Allowance')) !== -1;
            },
            partner_other_benefit_widowedparentsallowance_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_widowedparentsallowance_status', 'Getting now');
            },
            partner_other_benefit_widowedparentsallowance_frequency: ["partner_other_benefit_widowedparentsallowance_amount"],
            partner_other_benefit_workingtaxcredit_status: function () {
              return this.lib.visibleAndArrayContainsElement('partner_other_benefit_type', 'Working Tax Credit') !== -1;
            },
            partner_other_benefit_workingtaxcredit_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_workingtaxcredit_status', 'Getting now');
            },
            partner_other_benefit_workingtaxcredit_frequency: ["partner_other_benefit_workingtaxcredit_amount"],

            applicant_other_benefit_pension_number: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_pension', 'Yes');
            },

            applicant_other_benefit_pension_defer_lump_amount: function () {
              return this.lib.visibleAndIcontains('applicant_other_benefit_pension_defer_lump', 'Yes');
            },
            partner_other_benefit_pension: ["couple_livingaway"],
            partner_other_benefit_pension_number: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_pension', 'Yes');
            },
            partner_other_benefit_pension_defer: ["couple_livingaway"],
            partner_other_benefit_pension_defer_lump: ["couple_livingaway"],
            partner_other_benefit_pension_defer_lump_amount: function () {
              return this.lib.visibleAndIcontains('partner_other_benefit_pension_defer_lump', 'Yes');
            },
            applicant_other_income: ["applicant_livingaway"],
            couple_other_income: ["couple_livingaway"],
            applicant_other_income_no: function () {
              return this.lib.visibleAndIcontains('applicant_other_income', 'Yes') || this.lib.visibleAndIcontains('couple_other_income', 'Yes');
            },

            applicant_accounts: ["applicant_livingaway"],

            couple_accounts: ["couple_livingaway"],

            applicant_capital: ["applicant_livingaway"],
            applicant_capitaldetails: function () {
              return this.lib.visibleAndIcontains('applicant_capital', 'Yes');
            },
            applicant_nsc: ["applicant_livingaway"],
            applicant_stocks: ["applicant_livingaway"],
            applicant_stocks_number: function () {
              return this.lib.visibleAndIcontains('applicant_stocks', 'Yes');
            },

            couple_capital: ["couple_livingaway"],
            couple_capitaldetails: function () {
              return this.lib.visibleAndIcontains('couple_capital', 'Yes');
            },
            couple_nsc: ["couple_livingaway"],
            couple_stocks: ["couple_livingaway"],
            couple_stocks_number: function () {
              return this.lib.visibleAndIcontains('couple_stocks', 'Yes');
            },

            applicant_savings: ["applicant_livingaway"],
            applicant_savingsno: function () {
              return this.lib.visibleAndIcontains('applicant_savings', 'Yes');
            },
            applicant_savingsvalue: ["applicant_savingsno"],
            applicant_premiumbonds: ["applicant_livingaway"],
            applicant_premiumbondsno: function () {
              return this.lib.visibleAndIcontains('applicant_premiumbonds', 'Yes');
            },
            applicant_premiumbondsvalue: ["applicant_premiumbondsno"],
            applicant_isa: ["applicant_livingaway"],
            applicant_isano: function () {
              return this.lib.visibleAndIcontains('applicant_isa', 'Yes');
            },
            applicant_isavalue: ["applicant_isano"],
            applicant_incomebonds: ["applicant_livingaway"],
            applicant_incomebondsno: function () {
              return this.lib.visibleAndIcontains('applicant_incomebonds', 'Yes');
            },
            applicant_incomebondsvalue: ["applicant_incomebondsno"],
            applicant_trust: ["applicant_livingaway"],
            applicant_trust_property: function () {
              return this.lib.visibleAndIcontains('applicant_trust', 'Property') || this.lib.visibleAndIcontains('applicant_trust', 'Both');
            },
            applicant_trust_property_value: ["applicant_trust_property"],
            applicant_trust_money: function () {
              return this.lib.visibleAndIcontains('applicant_trust', 'Money') || this.lib.visibleAndIcontains('applicant_trust', 'Both');
            },
            applicant_savingsother: ["applicant_livingaway"],
            applicant_savingsotherdetails: function () {
              return this.lib.visibleAndIcontains('applicant_savingsother', 'Yes');
            },
            applicant_savingsothervalue: ["applicant_savingsotherdetails"],
            applicant_savingsinclude: ["applicant_livingaway"],
            applicant_property: ["applicant_livingaway"],
            couple_savings: ["couple_livingaway"],
            couple_savingsno: function () {
              return this.lib.visibleAndIcontains('couple_savings', 'Yes');
            },
            couple_savingsvalue: ["couple_savingsno"],
            couple_premiumbonds: ["couple_livingaway"],
            couple_premiumbondsno: function () {
              return this.lib.visibleAndIcontains('couple_premiumbonds', 'Yes');
            },
            couple_premiumbondsvalue: ["couple_premiumbondsno"],
            couple_isa: ["couple_livingaway"],
            couple_isano: function () {
              return this.lib.visibleAndIcontains('couple_isa', 'Yes');
            },
            couple_isavalue: ["couple_isano"],
            couple_incomebonds: ["couple_livingaway"],
            couple_incomebondsno: function () {
              return this.lib.visibleAndIcontains('couple_incomebonds', 'Yes');
            },
            couple_incomebondsvalue: ["couple_incomebondsno"],
            couple_trust: ["couple_livingaway"],
            couple_trust_property: function () {
              return this.lib.visibleAndIcontains('couple_trust', 'Property') || this.lib.visibleAndIcontains('couple_trust', 'Both');
            },
            couple_trust_property_trust: ["couple_trust_property"],
            couple_trust_money: function () {
              return this.lib.visibleAndIcontains('couple_trust', 'Money') || this.lib.visibleAndIcontains('couple_trust', 'Both');
            },
            couple_cash: ["couple_livingaway"],
            couple_cashamount: function () {
              return this.lib.visibleAndIcontains('couple_cash', 'Yes');
            },
            couple_savingsother: ["couple_livingaway"],
            couple_savingsotherdetails: function () {
              return this.lib.visibleAndIcontains('couple_savingsother', 'Yes');
            },
            couple_savingsothervalue: ["couple_savingsotherdetails"],
            couple_savingsinclude: ["couple_livingaway"],
            couple_property: ["couple_livingaway"],

            applicant_completedby_reason: function () {
              return this.lib.visibleAndIcontains('applicant_completedby', 'Yes');
            },
            applicant_completedby_forename: ["applicant_completedby_reason"],
            applicant_completedby_lastname: ["applicant_completedby_reason"],
            applicant_completedby_addressline1: ["applicant_completedby_reason"],
            applicant_completedby_addressline2: ["applicant_completedby_reason"],
            applicant_completedby_addressline3: ["applicant_completedby_reason"],
            applicant_completedby_postcode: ["applicant_completedby_reason"],
            applicant_completedby_tel: ["applicant_completedby_reason"],
            applicant_completedby_relationship: ["applicant_completedby_reason"],
            applicant_completedby_declaration: ["applicant_completedby_reason"],
            applicant_letterofauthority_name: function () {
              return this.lib.visibleAndIcontains('applicant_letterofauthority', 'Yes');
            },
            applicant_letterofauthority_addressline1: ["applicant_letterofauthority_name"],
            applicant_letterofauthority_addressline2: ["applicant_letterofauthority_name"],
            applicant_letterofauthority_addressline3: ["applicant_letterofauthority_name"],
            applicant_letterofauthority_postcode: ["applicant_letterofauthority_name"],
            applicant_letterofauthority_tel: ["applicant_letterofauthority_name"],
            applicant_letterofauthority_relationship: ["applicant_letterofauthority_name"],
            applicant_letterofauthority_declaration: function () {
              return this.lib.visibleAndIcontains('applicant_letterofauthority', 'Yes');
            },
            partner_declaration: ["partner_title"],

            group_other_adults: function () {
              return this.lib.visibleAndIcontains('other_adults', 'Yes');
            },
            sum_of_adults_other_adults: ["group_other_adults"],

            help_applicant_self_employed_time: ["applicant_kind_of_work_se"],
            section_applicant_self_employed_time: ["applicant_kind_of_work_se"],
            help_partner_self_employed_time: ["partner_kind_of_work_se"],
            section_partner_self_employed_time: ["partner_kind_of_work_se"],

            template_applicant_landlord_address_lookup: ["applicant_landlord"],
            help_applicant_payment_difficulties_reason: ["applicant_payment_difficulties_reason", 'rent_charge'],

            applicant_care: function () {
              return dateUtils.isYoungerThan(this.lib.getModelVisible('applicant_dob'), 22);
            },
            partner_righttostay: function () {
              return dateUtils.isYoungerThan(this.lib.getModelVisible('partner_residentsincedate'), 5);
            },
            partner_asylum: function () {
              return dateUtils.isYoungerThan(this.lib.getModelVisible('partner_residentsincedate'), 5);
            },
            partner_care: function () {
              return dateUtils.isYoungerThan(this.lib.getModelVisible('partner_dob'), 22);
            },

            has_other_adults: function () {
              return this.lib.visibleAndIcontains('other_adults', 'Yes');
            },
            has_joint_tenant: function () {
              return this.lib.visibleAndGtOrEq('jointtenants', 1);
            },
            has_boarder: [
              function () {
                return this.lib.visibleAndGtOrEq('boarders', 1);
              }
            ],
            your_landlord: function () {
              return this.isRentingFromLandlordOrHousingAssociation();
            },
            applicant_rentcharge_startdate: function () {
              return this.isRentingFromLandlordOrHousingAssociation();
            },
            applicant_water_bill_seperate: ["applicant_landlord"],
            partner_employed_pension: ["partner_title"]
          }
        });

        service.isApplicantReceivingUniversalCredit = function () {
          return this.visibleAndInList('applicant_uc', ["Yes", "Waiting to hear"]);
        };

        service.isReceivingUniversalCredit = function () {
          var coupleUniversalCredit = this.visibleAndInList('couple_uc', [
            "I get Universal Credit",
            "My partner gets Universal Credit",
            "We both get Universal Credit",
            "We are waiting to hear about our claim"]);

          return coupleUniversalCredit || this.isApplicantReceivingUniversalCredit();
        };

        service.isPermOrTempAccommodation = function () {
          return this.visibleAndInList('household_perm_temp_accommodation', [
            "I live in permanent accommodation",
            "I live in temporary accommodation"]);
        };

        service.isRentingFromLandlordOrHousingAssociation = function (coupleStatus) {
          var coupleStatusResult = coupleStatus ? this.visibleAndIcontains('couple', coupleStatus) : true;
          return coupleStatusResult && this.visibleAndInList('household_rent_charger',
            ['I am charged rent by a housing association', 'I am charged rent by a private landlord']);
        };

        return service;
      }]);

  }(angular)
);
