'use strict';

(function () {

  var mod = angular.module('kerp-forms.forms');

  mod.directive('schemaFieldKerpdate', ['$moment', 'schemaForm', 'sfValidator', 'sfPath',
    function ($moment, schemaForm, sfValidator, sfPath) {

      return {
        restrict: 'EA',
        scope: true,
        require: 'ngModel',
        link: function (scope, element, attrs, ngModelController) {

          var error;
          var DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';

          scope.$emit('schemaFormPropagateNgModelController', ngModelController);

          // rewrite the scope on form change
          scope.$watch(attrs.schemaFieldKerpdate, function (form) {

            var storedDateFormat = form.dateFormat || DEFAULT_DATE_FORMAT;

            // try to get the existing
            scope.modelDateString = ngModelController.$modelValue;

            // construct path to current model scope property, model is exposed in prototype
            var modelPath = 'model' + sfPath.normalize(form.key);

            // watch for changes from outside
            scope.$watch(modelPath, function () {
              scope.modelDateString = ngModelController.$modelValue;
            });

            function onModelDateStringChange() {

              if (scope.modelDateString) {

                var dateInstanceFromString = $moment(scope.modelDateString, storedDateFormat);
                if ((!scope.modelDateInstance || !angular.isDate(scope.modelDateInstance)) && dateInstanceFromString) {
                  scope.modelDateInstance = dateInstanceFromString.toDate();
                }
              }
              scope.validateDate();
            }

            // Converting string to date instance, this happens when value in the model is changed
            scope.$watch('modelDateString', onModelDateStringChange);

            function onModelDateInstanceChange(newVal, oldVal) {

              if (newVal) {
                var modelDate = $moment(scope.modelDateInstance);
                var isValid = scope.modelDateInstance && angular.isDate(scope.modelDateInstance);

                isValid = isValid && (!form.maxDate || modelDate.isSameOrBefore(form.maxDate, 'day')) && (!form.minDate || modelDate.isSameOrAfter(form.minDate, 'day'));

                ngModelController.$setValidity('invalidDate', isValid);

                if (isValid) {
                  scope.modelDateString = modelDate.format(storedDateFormat);
                  ngModelController.$setDirty();
                }

                ngModelController.$setViewValue(scope.modelDateString);

              } else if (!newVal && oldVal) {
                ngModelController.$setValidity('invalidDate', true);
                scope.modelDateString = undefined;
                ngModelController.$setDirty();
                ngModelController.$setViewValue(undefined);
              }
            }

            scope.$watch('modelDateInstance', onModelDateInstanceChange);

            // validation
            scope.$on('schemaFormValidate', function () {
              scope.validateDate();
              ngModelController.$setDirty();
            });

            scope.validateDate = function () {

              var result = sfValidator(form, scope.modelDateString);

              // TODO: DRY this up, it has a lot of similarities with schema-validate
              // Since we might have different tv4 errors we must clear all errors that start with tv4-
              Object.keys(ngModelController.$error)
                .filter(function (k) {
                  return k.indexOf('tv4-') === 0;
                })
                .forEach(function (k) {
                  ngModelController.$setValidity(k, true);
                });

              // Set viewValue to trigger $dirty on field. If someone knows a a better way to do it please tell.
              ngModelController.$setViewValue(scope.modelDateString);

              if (result.valid === false && result.error && (result.error.dataPath === '' || result.error.dataPath === '/' + form.key[form.key.length - 1])) {
                error = result.error;
                ngModelController.$setValidity('tv4-' + result.error.code, false);
              }

            };

            scope.hasSuccess = function () {
              return ngModelController.$valid && !ngModelController.$pristine;
            };

            scope.hasError = function () {
              return ngModelController.$invalid && !ngModelController.$pristine;
            };

            scope.schemaError = function () {
              return error;
            };

            // Datepicker popup
            scope.popup = {
              opened: false
            };

            scope.openDatePicker = function () {
              scope.popup.opened = true;
            };

            scope.datePickerOptions = {
              datepickerMode: form.mode
            };

            if (form.maxDate) {
              scope.datePickerOptions.maxDate = $moment(form.maxDate);
            }
            if (form.minDate) {
              scope.datePickerOptions.minDate = $moment(form.minDate);
            }
          });

          function keyupHandler() {
            ngModelController.$setDirty();
          }

          var input$ = element.find('[uib-datepicker-popup]');

          input$.on('keyup', keyupHandler);

          scope.$on('$destroy', function () {
            input$.off('keyup', keyupHandler);
          });
        }
      };
    }]);
}());
